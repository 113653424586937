import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FaRegEdit } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import { db } from '../Firebase';
import CommonDialog from './CommonDialog';
import Button from '@material-ui/core/Button'

const styles = {
  // Cards
  card: {
    width: '90%',
//    margin: '10px',
    'margin-left': 'auto',
    'margin-right': 'auto',
    'margin-top': '10px',
    'margin-bottom':'10px',
//    marginBottom: 10,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    'text-align': 'center',
    'white-space': 'nowrap',
    '-webkit-text-overflow': 'ellipsis',
    '-o-text-overflow': 'ellipsis',
  },
  empty: {
//    width: 330,
    height: 0,
    margin: 0,
    padding: 0,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  editicon: {
    width: '20px',
    height: '20px',
    margin: '0 auto',
  },
  iconrapper: {
    display: 'block',
    width: '100%',
  }
};

function Item(props) {
  const { empty, title, imgUrl, price, href, classes, uid , itemid ,getMylist ,deletable,status} = props;
  const [commDlg, setCommDlg] = useState(false);
  const delMydata = () => {
    db.collection('users').doc(uid).collection('items').doc(itemid)
      .set({ status: "delete" }, { merge: true })
//      .then(getMylist());
//      .then(setTimeout(getMylist(), 500) );
    setCommDlg(false)
  }

  const handleOnclick = () => {
    console.log("commDlg:",commDlg)
    setCommDlg(true)
  }

  const exchangeUrl = (s1) => {
    //https://firebasestorage.googleapis.com/v0/b/joino-fc334.appspot.com/o/userfiles%2FpjN1W1a1V5ZF09iBPcSa5Zo5xb22%2F9HW73oYCBEXXXEDqo8ms%2Fimages%2FS58b6e5204cb14962976c89cda7c87a2b7.jpg?alt=media&token=047c1454-af3c-4929-a70b-584a291e289b
    if (s1) {
      if (s1.indexOf("firebasestorage.googleapis.com") == -1) {
        return s1.replace(/https:\/\/(\w+)/g, "https://joino.me/api/$1");
      }
      return s1
    }
    return null;
  };

  if (empty === "true") {
    return (
      <Card className={classes.empty}>
      </Card>
    );
  } else {
    return (
      <Card className={classes.card}>
        <CommonDialog
          msg={"削除しますか？"}
          isOpen={commDlg}
          doYes={delMydata}
          doNo={() => {setCommDlg(false)}}
        />
        <CardContent>
          <Typography variant="headline"  className={classes.title}>
            {title}
          </Typography>
          <br />
          <a href={href} target="_blank">
            <img src={exchangeUrl(imgUrl)} width="80%" />
          </a>
        </CardContent>
        <CardActions>
          <div className={classes.row}>
            {deletable ? <p className={classes.iconrapper}> <Button className={classes.editicon } onClick={() => { handleOnclick(); }}><DeleteIcon onClick={() => { handleOnclick(); }} className={classes.editicon} /></Button></p> : <></>} 
            <Typography  component="p"  className={classes.iconrapper}>
          {price}
          </Typography>
            {status ? <>
              <Typography  component="p"  className={classes.iconrapper}>
              {status}
              </Typography>
            </> : <></>
            }
            {/*<p className={classes.iconrapper}><a href={href} target="_blank"><FaRegEdit className={classes.editicon}/></a></p>*/}
          </div>
        </CardActions>
      </Card>
    );
  }
}

Item.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Item);