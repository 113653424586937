import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
});

class CommonDialog2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
           open:false,
        }
      }
    handleChange = e => {
        this.setState({textvalue:e.target.value} );
    };
    
  render() {
    const { classes, msg, label, helperText, submit } = this.props;
    return (
        <div>
        <Dialog
            open={true}
            keepMounted
            //onClose={}
            aria-labelledby="common-dialog-title"
            aria-describedby="common-dialog-description"
        >
        <DialogContent>
        {msg}
        </DialogContent>
        <DialogActions>
            <TextField
                id="code"
                name="code"
                label={ label}
                style={{ margin: 8 }}
                helperText={helperText }
                // fullWidth
                margin="normal"
                variant="outlined"
                autoComplete='off'
                msg={console.log('')}
                value={this.state.textvalue}
                onChange={e => this.handleChange(e)}
                required="true"
            />
            <Button onClick={() => {submit(this.state.textvalue)}} color="primary">
                実行
            </Button>
        </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CommonDialog2.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(CommonDialog2);