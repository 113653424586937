import React from 'react';
import { Link,} from 'react-router-dom';


import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


// スタイル
const styles = theme => ({
  titleImage: {
    width: '100%',
    maxWidth: 700,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});


class Home extends React.Component {

  render() {

    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <img src="/images/joino.png" alt="title" className={classes.titleImage} />
        <h2>joinoはSNSアカウントの信用を高め約束やおすすめを繋ぐサービスです</h2>
        <div className={classes.textLeft}>
        
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            joinopromiとは
            </Typography>
            <Typography component="p">
              SNSアカウント同士の約束を保護しSNSアカウントに仮想信用を付与します。
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            joinoshopiとは
            </Typography>
            <Typography component="p">
              SNSアカウントだけで簡単に始められる商品紹介ビジネスです。成果報酬は振り込みpaypay等で受け取れます。
            </Typography>
            <Link to="/l/pjN1W1a1V5ZF09iBPcSa5Zo5xb22/sppALL">商品一覧はこちら</Link>
          </Paper>
        </div>
        <Typography component="subtitle2">
          <Link to="/specifiedcommercialtransactionact">特定商取引法に基づく表記</Link>
          </Typography>
      </div>
    );
  }
}

// Material-ui関連
Home.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(Home);