//require('dotenv').config();
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";
import "firebase/storage";
//import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId:process.env.REACT_APP_MEASUREMENT_ID
};


//firebase.initializeApp(firebaseConfig);
//firebase.analytics();
//Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  //firebase.analytics();
}

export default firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();