import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,  
  },
});


class SignUp extends React.Component {

  constructor(props) {
    super(props);
  }
  

  signin = () => {
      // ログイン画面に遷移
    this.props.history.replace('/signin');
  }
  
  
  render() {

    // Material-ui関連
    const { classes } = this.props;
    
    return (
      <div>
        <h2>新規登録</h2>
        <div className={classes.root}>
            <Paper className={classes.root} elevation={1}>            
             <Typography variant="headline" component="h3">
                SNSアカウントで新規登録
             </Typography>
            <Typography variant="headline" component="p">
                <br />
                新規登録には招待コードが必要です<br />
                新規登録する際にはSNSアカウントの認証を求められます<br />

                認証する前に目的のSNSアカウントでログインしている必要があります<br />
                下記のリンクからログインアカウントを確認し必要があれば変更してください<br />
                スマホはリンクを長押ししてブラウザの別タブで開いてください<br />
                スマホアプリがログインしているアカウントは反映されません<br />
                <a href="https://mobile.twitter.com/home" target="linkchangeWindow" onclick={(e) =>this.openTwitterPopup(e)}>Twitter</a>
                　<a href="https://myaccount.google.com/" target="linkchangeWindow" onclick={(e) =>this.openGooglePopup(e)}>Google</a>
                <br />
                <br />
                目的のアカウントを確認したら<br />
                下のボタンを押して、画面の指示に従って新規登録して認証してください<br />
                <Button className={classes.providerbotton} onClick={this.signin}>
              <span>
              <ExitToAppIcon className={classes.providericon} alt="登録します"/>
              </span>
                  <span > Sign Up to Joino:</span>
              </Button>
            </Typography>
                <br />
             </Paper>
        </div>
      </div>
    );
  }
}

// Material-ui関連
SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(SignUp);