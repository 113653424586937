import React, { Component } from 'react';

// コンテナ読み込み

import ResponsiveDrawer from './containers/ResponsiveDrawer';
import RouteRelatedBottomNavigation from './containers/RouteRelatedBottomNavigation';
import Notification from './containers/Notification';
import Info from './containers/Info';
import Settings from './containers/Settings';
import PrivacyPolicy from './containers/PrivacyPolicy';
import SpecifiedCommercialTransactionAct from './containers/SpecifiedCommercialTransactionAct';
import JoinoShopi from './containers/JoinoShopi';
import JoinoPromi from './containers/JoinoPromi';
import ItemList from './containers/ItemList';
import ItemEdit from './containers/ItemEdit2';
//import ItemEdit2 from './containers/ItemEdit2';
import I from './containers/I';
//import I2 from './containers/I2';
import L from './containers/L';
import Checkout from './containers/Checkout';
import Myfav from './containers/Myfav';
import SignInOrUp from './containers/SignInOrUp';
import SignUp from './containers/SignUp';
import MailSignUp from './containers/MailSignUp';
import Home from './containers/Home';
import Test from './containers/Test';
import AuthRoute from './AuthRoute';

// コンポーネント読み込み
import WrapMainContent from './components/WrapMainContent'

// 共通スタイル読み込み
import './App.css';

// Route関連
import { Route, Switch, useLocation} from 'react-router-dom';
//import { Cancel } from '@material-ui/icons';
//import { testMatrix } from 'firebase-functions/v1/testLab';

// 不明なRouteは全てNotFound
const NotFound = () => {
  return(
    <h2>ページが見つかりません</h2>
  )
}


class App extends Component {
// フッターを一時削除した  </ResponsiveDrawer>直下　<RouteRelatedBottomNavigation/>
  render() {
    //const location = useLocation();
    //            {uId ? uId[1]:''}
    //const uId = location.pathname.match(/i\/(.*)\//);
    //const Path = location.pathname;
    //const uId = Path.match(/i\/(.*)\//);

    return (
      <div className="App">
        <Notification />
        <ResponsiveDrawer className="ResponsiveDrawer">
          <Switch>
            <Route exact path="/" component={WrapMainContent(Home)} />
            <Route exact path="/joinopromi" component={WrapMainContent(JoinoPromi)} />
            <Route exact path="/joinoshopi" component={WrapMainContent(JoinoShopi)} />
            <Route exact path="/info" component={WrapMainContent(Info)} />
            <Route exact path="/settings" component={WrapMainContent(Settings)} />
            <Route exact path="/privacypolicy" component={WrapMainContent(PrivacyPolicy)} />
            <Route exact path="/specifiedcommercialtransactionact" component={WrapMainContent(SpecifiedCommercialTransactionAct)} />
            <Route exact path="/myfav" component={WrapMainContent(Myfav)} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/xxx__test__xxx" component={WrapMainContent(Test)} />
            <Route path="/mailsignup/:ref" component={MailSignUp} />
            <Route path="/i/:userId/:itemId" component={WrapMainContent(I)} />
            <Route path="/l/:userId/:tagName" component={WrapMainContent(L)} />
            <Route path="/checkout/:success/:orderId" component={WrapMainContent(Checkout)} />
            <AuthRoute path="/signin" component={WrapMainContent(SignInOrUp)} />
            <AuthRoute path="/itemlist" component={WrapMainContent(ItemList)} />
            <AuthRoute path="/itemedit/:itemId" component={WrapMainContent(ItemEdit)} />
            <Route component={WrapMainContent(NotFound)} />
          </Switch>
        </ResponsiveDrawer >
      </div>
    );
  }
}

// React-Router情報取得
export default App;
