import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';


import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import firebase, { db } from '../Firebase';
import { hslToRgb } from '@mui/system';


// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
  img: {
    width: '80%',
    'backface-visibility': 'hidden',
  },
});



class Checkout extends React.Component {
  constructor(Props) {
    super(Props)
    this.handleChange = this.handleChange.bind(this);
  }
  state = { addresslist: [], orderdat: {}, shippingAddress:'', user: {zip:'',address1:'',address2:'',address3:'',name:'',tel:''} ,error:""};
//  state = { addresslist: [], orderdat: {}, user: {} ,error:""};

  getSessionAndCustomer = async (sessionId) => {
    // セッション作成functionをインスタンス化
    const getStripeSessionAndCustomerBySessionId = firebase.app().functions('asia-northeast1').httpsCallable("getStripeSessionAndCustomerBySessionId");
    getStripeSessionAndCustomerBySessionId(sessionId)
      .then((result) => {
        console.log("SessionAndCustomer:" + result);
        /*
      data
        session
        {
          "id": "cs_test_AaoLnryG7dLlFxArfPRED2w5AS7ERfKPlWTNolgMe4iB1pyuBRss3b57",
          "object": "checkout.session",
          "after_expiration": null,
          "allow_promotion_codes": null,
          "amount_subtotal": null,
          "amount_total": null,
          "automatic_tax": {
            "enabled": false,
            "status": null
          },
          "billing_address_collection": null,
          "cancel_url": "https://example.com/cancel",
          "client_reference_id": null,
          "consent": null,
          "consent_collection": null,
          "currency": null,
          "customer": null,
          "customer_details": null,
          "customer_email": null,
          "expires_at": 1633746085,
          "livemode": false,
          "locale": null,
          "metadata": {},
          "mode": "payment",
          "payment_intent": "pi_1EUnBEF5IfL0eXz99dkRR60n",
          "payment_method_options": {},
//          "payment_method_types": ["card"], //コンソールで指定可能になったため削除 22/6/13
          "payment_status": "unpaid",
          "phone_number_collection": {
            "enabled": false
          },
          "recovered_from": null,
          "setup_intent": null,
          "shipping": null,
          "shipping_address_collection": null,
          "submit_type": null,
          "subscription": null,
          "success_url": "https://example.com/success",
          "total_details": null,
          "url": null
        }
        customer
        {
          "id": "cus_KNDsRkFW11Nslb",
          "object": "customer",
          "address": null,
          "balance": 0,
          "created": 1633739341,
          "currency": "jpy",
          "default_source": null,
          "delinquent": false,
          "description": null,
          "discount": null,
          "email": null,
          "invoice_prefix": "61E81F6",
          "invoice_settings": {
            "custom_fields": null,
            "default_payment_method": null,
            "footer": null
          },
          "livemode": false,
          "metadata": {},
          "name": null,
          "next_invoice_sequence": 1,
          "phone": null,
          "preferred_locales": [],
          "shipping": null,
          "tax_exempt": "none"
        }
        */
        try {
          // stripe_customerコレクションにドキュメントを追加(マージモード)
          db.collection('/customer').doc(result.data.customer.email).set({
            id: result.data.customer.id,
            email: result.data.customer.email,
            name: result.data.customer.name,
            phone: result.data.customer.phone,
          },
            { merge: true }
          );
          //console.log('paymentIntent:' +  JSON.stringify(paymentIntent));
          const ordercol = db.collection('/order')
          ordercol.where("payment_intentid", "==", result.data.session.payment_intent)
            .get()
            .then((querySnapshot) => {
              //console.log("querySnapshot.exists: ", querySnapshot.exists);
              //const dat = querySnapshot.docs[0].data();
              //console.log("paymentIntent.dat: ", dat);
              //console.log("paymentIntent.id: ", paymentIntent.id);
              //console.log("querySnapshot.id: ", id);
              //const Ref = db.collection('/order').doc(id);
              if (querySnapshot.docs.length) {
                console.log("snapshot.length", querySnapshot.docs.length)
                ordercol.doc(querySnapshot.docs[0].id)
                .set({
                  status: "payment_intent",
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  customerid: result.data.customer.id,
                  email: result.data.customer.email,
                  name: result.data.customer.name,
                  phone: result.data.customer.phone,
                },{ merge: true });
              }
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
        }
        catch (error) {
          console.log("Error getting documents: ", error);
        }
        console.log("getSessionAndCustomer: compleate");
        setTimeout("window.location.reload(true)",1000)
        /*
        this.setState({
          loading : false
        });
        */
      });
  };  
  
  
  //データ取得
  //oderIdで指定したoderの値を取得
  getoderData = async (oderId) => {
    const orderdocRef = db.collection('/order').doc(oderId);
    const orderdoc = await orderdocRef.get();
    var shippingAddress_all_ready_exist;
    console.log("getdat::")

    //ドキュメントの存在確認
    if (orderdoc.exists) {
      console.log("orderdoc.exists");
      const orderdat = orderdoc.data();
      if (orderdat.status == "payment_intent") {
        this.getcustomerData(orderdat);
      }
      else {
        this.getSessionAndCustomer(orderdat.stripesessionid);
        if (orderdat.shippingAddress) {
          console.log("shippingAddress all ready exist");
          shippingAddress_all_ready_exist = true;
        }
        else {
          shippingAddress_all_ready_exist = false;          
        }
        this.setState({
          orderdat: orderdat,
          shippingAddress_all_ready_exist:shippingAddress_all_ready_exist,
        });    
      }
    } else {
      console.log("no_data_orderdoc");
      this.setState({
        orderdat: "no_data",
      });
    } 
  }

  getcustomerData = async (orderdat) => {
    console.log("orderdat.email",orderdat.email);
    const customerRef = db.collection('/customer').doc(orderdat.email);
    const customerdoc = await customerRef.get();
    let customerdat={};
    let addresslist=[];
    let shippingAddress_all_ready_exist = false;
    if (customerdoc.exists) {
      customerdat = customerdoc.data();
      console.log("get_customerdata:: ", customerdat);
      addresslist = customerdat.addresslist;
    } else {
      console.log("no_customerdata");
    }
    if (orderdat.shippingAddress) {
      console.log("shippingAddress all ready exist");
      shippingAddress_all_ready_exist = true;
    }
    this.sendmail({ destination: "info01@joino.me", subject: "新規チェックアウトが発生しました", text: JSON.stringify({ customerdat: customerdat, orderdat: orderdat }, null , "\t")});
    this.setState({
      customer: customerdat,
      addresslist: addresslist,
      orderdat: orderdat,
      shippingAddress_all_ready_exist:shippingAddress_all_ready_exist,
    });
  }

  handleChange = e => {
    const params = this.state.user;
    params[e.target.name] = e.target.value;
    this.setState({ user: params });
    //console.log("handleCange::" + e.target.name + ": " + e.target.value)
  };
  
  handleshippingAddressChange = e => {
    if (e.target.checked) {
      console.log("shippingAddress: ", e.target.value)
      this.setState({ shippingAddress: e.target.value });
    }
  };

  onBlurZipcode = () => {
    console.log("onBlurZipcode");
    this.setState({
      user: {
        ...this.state.user,
        address1: document.getElementById('address1').value,
        address2: document.getElementById('address2').value,
        address3: document.getElementById('address3').value
      }
    });
  };

  sendmail = (data) => {
    const _sendmail = firebase.app().functions('asia-northeast1').httpsCallable("sendMail");
    _sendmail(data).then((result) => {
        console.log("result:" + result);
    });    
  }

  submitonClick = () => {
    var shippingAddress = ''
    if (this.state.shippingAddress == "new" || this.state.shippingAddress == "") {
      var errortxt = ''
      var zip = document.getElementsByName('zip')[0].value;
      if (!zip) {
        errortxt += "郵便番号 ";
      }
      var address1 = document.getElementsByName('address1')[0].value;
      if (!address1) {
        errortxt += "県 "
      }
      var address2 = document.getElementsByName('address2')[0].value;
      if (!address2) {
        errortxt += "市区町村 "
      }
      var address3 = document.getElementsByName('address3')[0].value;
      if (!address3) {
        errortxt += "町丁目番地その他 "
      }
      var name = document.getElementsByName('name')[0].value;
      if (!name) {
        errortxt += "名前 "
      }
      var tel = document.getElementsByName('tel')[0].value;
      if (!tel) {
        errortxt += "電話番号 "
      }
      if (errortxt) {
        errortxt  = errortxt + "を入力してください"
        console.log("error:" + errortxt);
        this.setState({ error: errortxt });
        return
      }
      const customer_Ref = db.collection('/customer').doc(this.state.customer.email);
      customer_Ref.update({
        addresslist: firebase.firestore.FieldValue.arrayUnion({
          zip: zip,
          address1: address1,
          address2: address2,
          address3: address3,
          name: name,
          tel: tel,
        })
      });
      shippingAddress = zip + " " + address1 + address2 + address3 + " " + name + " tel: " + tel;
      console.log("shippingAddress:" + shippingAddress);
    } else if (this.state.shippingAddress == "ambassador") {
      shippingAddress = "ambassador";
    }
    else {
      shippingAddress = this.state.shippingAddress;
    }
    const orderdocRef = db.collection('/order').doc(this.props.match.params.orderId);
    orderdocRef.set({
      shippingAddress: shippingAddress
    }, { merge: true }).then(setTimeout("window.location.reload(true)", 1000));
    this.sendmail({ destination: "info01@joino.me", subject: "新規チェックアウトが正常終了しました", text: JSON.stringify({ customerdat: this.state.customerdat, orderdat: this.state.orderdat,shippingAddress:shippingAddress }, null , "\t") });
  }

  zenNum2HanNum = (num) => {
    var z = ["０","１","２","３","４","５","６","７","８","９"];
    for (var i = 0; i < 10; i++) num = num.replace(new RegExp(z[i], "g"), i);
    num = num.replace(new RegExp("[^0-9]", "g"), "");
    return num;
  }
  
  complementAddress = () => {
    console.log("complementAddress");
    const { AjaxZip3 } = window;
    AjaxZip3.zip2addr(
      'dummy',
      'zip',
      'address1',
      'address2',
      'address3',
    );
  };

  //値を取得
  componentDidMount = () => {
    this.getoderData(this.props.match.params.orderId);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot){
    //stateが更新されると呼ばれるやつ
    console.log("state:changed")
  }


//id:cs_test_a1CExfltggrK0rcrWcKlnJJxUCwjvWJqmASZ0Sd6o9KPtyzyqpIafOQaX5
  
  /*
    stripesessionid:session.id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    description: description,
    price: price,
    productname: name,
    productid:productid,
    image:image,
    ambassadorid: ambassadorid,
    payment_intentid: session.payment_intent,
    session:session,

  */

  render() {
    console.log("render");
    // Material-ui関連
    const { classes } = this.props;
    const addresslist = this.state.addresslist;
    const orderdat = this.state.orderdat;
    const error = this.state.error;
    const shippingAddress_all_ready_exist = this.state.shippingAddress_all_ready_exist;

    const success = this.props.match.params.success;
    const orderId = this.props.match.params.orderId;

    if (success == 'success') {
      if (!shippingAddress_all_ready_exist) {
        if (orderdat != 'Irregular_status' && orderdat != 'no_data') {
          if (orderdat.status != "payment_intent") {
            return (
              <div>
                <div class="overflow">
                  <Paper className={classes.root} elevation={1}>
                    <CircularProgress />
                    <Typography component="p">
                      "データの取得中です "
                    </Typography>
                  </Paper>
                </div>
              </div>
            );
          }
          else {
            return (
              <div className={classes.root} >
                <h2>決済が成功しました</h2>
                <div className={classes.textLeft}>
                <Paper className={classes.textCenter} elevation={1}>
                    <img src={orderdat.image} alt="pictuer" className={classes.img}/>
                </Paper>
                  <Paper className={classes.root} elevation={1}>
                    <Typography variant="headline" component="h3">
                      商品の送付先を記入してください
                    </Typography>
                  </Paper>
                  <form className={classes.root} noValidate autoComplete="off">
                    <Paper className={classes.root} elevation={1}>
                      <input type="radio" name="shippingAddress" value="ambassador" onChange={e => this.handleshippingAddressChange(e)}/>{orderdat.ambassadordisplayName}に送る
                    </Paper >
                    {
                      Array.isArray(addresslist) ?
                        addresslist.map((_lst, i1) => {
                          return (
                            <Paper className={classes.root} elevation={1}>
                              <div>
                                <input type="radio" name="shippingAddress" value={_lst.zip + " " + _lst.address1 + _lst.address2 + _lst.address3 + " " + _lst.name + " tel: " + _lst.tel}  onChange={e => this.handleshippingAddressChange(e)}/>
                                <span>この住所に送る<br />
                                  〒{_lst.zip} {_lst.address1} {_lst.address2}
                                  {_lst.address3} <br />
                                  {_lst.name}
                                  &nbsp;tel:{_lst.tel}
                                </span>
                              </div>
                            </Paper>
                          );
                        }) : ""
                    }
                    <Paper className={classes.root} elevation={1}>
                      <input type="radio" name="shippingAddress" value="new" onChange={e => this.handleshippingAddressChange(e)} />
                      新規送信先に送る
                    <input type ="hidden" name="dummy" value="" />
                    <div waidth="100%">
                    <TextField
                    id="zip"
                    name="zip"
                    label="郵便番号"
                    style={{ margin: 8 }}
                    helperText=""
                    margin="normal"
                    variant="outlined"
                    value={this.state.user.zip}
                    autoComplete='off'
                    onChange={e => this.handleChange(e)}
                    onKeyUp={this.complementAddress}
                    onBlur={this.onBlurZipcode}
                    required="true"
                    />
                    </div>
                    <TextField
                    id="address1"
                    label="県"
                    style={{ margin: 8 }}
                    helperText="県"
                    margin="normal"
                    variant="outlined"
                    name="address1"
                    autoComplete='off'
                    value={this.state.user.address1}
                    onChange={e => this.handleChange(e)}
                    required="true"
                    />
                    <TextField
                    id="address2"
                    label="市区町村"
                    style={{ margin: 8 }}
                    helperText="市区町村"
                    margin="normal"
                    name="address2"
                    variant="outlined"
                    autoComplete='off'
                    value={this.state.user.address2}
                    onChange={e => this.handleChange(e)}
                    required="true"
                    />
                    <TextField
                    id="address3"
                    label="町丁目番地建物部屋番号"
                    style={{ margin: 8 }}
                    helperText="町丁目番地建物部屋番号"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="address3"
                    autoComplete='off'
                    msg={console.log('render address3:' + this.state.user.address3)}
                    value={this.state.user.address3}
                    onChange={e => this.handleChange(e)}
                    required="true"
                    />
                    <TextField
                    id="standard-full-width"
                    label="名前"
                    style={{ margin: 8 }}
                    helperText=""
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="name"
                    autoComplete='off'
                    value={this.state.user.name}
                    onChange={e => this.handleChange(e)}
                    required="true"
                    />
                    <TextField
                    id="standard-full-width"
                    label="電話番号"
                    style={{ margin: 8 }}
                    helperText=""
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="tel"
                    autoComplete='off'
                    value={this.state.user.tel}
                    onChange={e => this.handleChange(e)}
                    required="true"
                    />
                    <div className={classes.textCenter}>
                  {
                    error
                    ?<Alert Alert severity="error">{error}</Alert>
                    : ""
                  }
                    <Button variant="contained" color="primary" className={classes.margin} onClick={this.submitonClick}>
                    送信
                    </Button>
                    </div>
                    </Paper>
                  </form>
              </div>
              </div >
            );              
          }
        }
        else {
          return (
            <div>
              <h2>この注文は決済が完了していません<br />しばらく後にアクセスしてください</h2>
              <a href={"/i/" + orderdat.ambassadorid + '/' + orderdat.productid}>
                <div className={classes.textLeft}>
                  <Paper className={classes.root} elevation={1}>
                    <img src={orderdat.image} alt="pictuer" className={classes.img}/>
                  </Paper>
                  <Paper className={classes.textCenter} elevation={1}>
                    <Typography variant="headline" component="h3">
                      状況を確認する
                    </Typography>
                    <Button variant="contained" color="primary" className={classes.margin}>
                  確認
                </Button>
                  </Paper>
                </div>
              </a>
            </div >
          );
        }        
      }
      else {
        return (
          <div>
            <h2>ご注文を下記の通り承りました</h2>
            <div className={classes.textLeft}>
              <Paper className={classes.textCenter} elevation={1}>
                <img src={orderdat.image} alt="pictuer" className={classes.img}/>
              </Paper>
              <Paper className={classes.root} elevation={1}>
                決済総額：{"￥" + orderdat.price.toLocaleString()}<br />
                （内送料：{orderdat.freightAmountString}）<br />
                送り先：{orderdat.shippingAddress == "ambassador"? orderdat.ambassadordisplayName:orderdat.shippingAddress}<br />
                選択オプション：{orderdat.description}<br />
                商品到着予定日：{orderdat.arrivalExpectedDate}<br />
                <a href="/specifiedcommercialtransactionact#Refundsupport">商品の返品について</a><br />
                <a href="/specifiedcommercialtransactionact#arrivalExpectedDate">商品到着予定日について</a><br />
                とうサイトにはマイページなど注文状況を確認するページはございません<br />
                ご登録のe-mailアドレスに注文の確認と途中経過を逐次送付させていただきます<br />
                ご質問等はそのe-mailにご返信をいただければと存じます
              </Paper>
              <Paper className={classes.textCenter} elevation={1}>
                <Typography variant="headline" component="h3">
                    購入画面に戻る
                </Typography>
                <a href={"/i/" + orderdat.ambassadorid + '/' + orderdat.productid}>
                <Button variant="contained" color="primary" className={classes.margin}>
                戻る
                </Button>
              </a>
              </Paper>          
            </div>
          </div >
        );          
      }
    }
    else {
      return (
        <div>
          <h2>決済がキャンセルされました</h2>
            <a href={"/i/" + orderdat.ambassadorid + '/' +orderdat.productid}>
              <div className={classes.textLeft}>
                <Paper className={classes.textCenter} elevation={1}>
                  <img src={orderdat.image} alt="pictuer" className={classes.img}/>
                </Paper>
              <Paper className={classes.root} elevation={1}>
                決済総額：{"￥" + orderdat.price.toLocaleString()}<br />
                （内送料：{orderdat.freightAmountString}）<br />
                商品到着予定日：{orderdat.arrivalExpectedDate}<br />
                カード会社にお問い合わせください
                </Paper>
                <Paper className={classes.root} elevation={1}>
                    <Typography variant="headline" component="h3">
                        購入画面に戻る
                    </Typography>
                </Paper>          
              </div>
          <Button variant="contained" color="primary" className={classes.margin}>
          戻る
        </Button>
        </a>
      </div >
      );
    }
  }
}

// Material-ui関連
Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(Checkout);