import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Search from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select';
import { MenuItem as Mi } from'@material-ui/core/';
//import Accordion from '@material-ui/core/Accordion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import firebase, { db } from '../Firebase';
import { DataUsageTwoTone } from '@material-ui/icons';


// コンポーネントの準備
import Item from '../components/Item'
import MenuItem from '../components/MenuItem'

// スタイル
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    'textAlign': 'center',
    'align': 'center',
    width:'90%',
  },
  textcenter: {
    width:'100%',
    align:'center',
    'white-space': 'nowrap',  
    overflow: 'hidden',  
    'text-overflow': 'ellipsis', 
    '-webkit-text-overflow': 'ellipsis',  
    '-o-text-overflow': 'ellipsis',
    'textAlign': 'center',
    'text-align':'center',
  },
  accordionSummary: {
    height: '15px',
    'min-height': '15px',
    padding: '0',
    margin: '0',
  },
  accordionDetails: {
    padding: '0',
    margin: '0',
    display: 'flex',
    'flex-wrap':'wrap',
//    'flex-flow': 'column',
  },
  'expanded': {
   'min-height': '15px',
    padding: '0',
    margin: '0',
  },
  resize:{
    height: '15px',
    fontSize: '12px',
    padding: '0',
    margin: '0',
    width:'20px',
  },
  searchbox: {
    width: '100%',
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
  },
  categlybox: {
    width: '100%',
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
  },
  inlinetext: {
    top:"-10px",
    fontSize: '12px',
  },
  searchbottn: {
    top:"-5px",
    'line-height': '16px',    
  },
  serchtext: {
    width:'50px'
  },
  pricetext: {
    width:'50px'
  },
  selectSortType: {
    fontSize: '10px',
    'line-height': '0px',
  },
});

class ItemList extends React.Component {
  constructor(props) {
    super(props)
   this.state = {
      favCategory: [],
      currentCategory: {categoryId:"0",categoryEnName:"root",categoryName:"Top"},
      categoryLst: [],
      itemList: [],
      getting: false,
      page: 1,
      error: '',
    }
  }
  
  // python hashlib.sha256("テスト".encode('utf-8')).hexdigest() と同等
  sha256 = async(text)=>{
    const uint8 = new TextEncoder().encode(text);
    const digest = await crypto.subtle.digest('SHA-256', uint8);
    const res = Array.from(new Uint8Array(digest)).map(v => v.toString(16).padStart(2, '0')).join('');
    return res
  }

  //python.get()
  get = (dic, key) => {
    if (dic && key in dic) return dic[key]
    return null
  }

  setData = (itemList, categorylst) => {
    let bufitemList = [];
    const page = this.state.page;
    if (page > 1) {
      bufitemList = bufitemList.concat(this.state.itemList);
    }
    bufitemList = bufitemList.concat(itemList.mods.itemList.content);
    console.log("itemList.length", bufitemList.length);
    this.setState({
      error:'',
      itemList: bufitemList,
      categoryLst: categorylst.childCategories,
      pathCategories: categorylst.pathCategories,
      getting:false,
    });
  }

  itemCahange = null;

  getMylist = () => {
    if (! this.itemCahange) {
      this.itemCahange = db.collection('users').doc(this.props.userdata.uid).collection('items')
        .onSnapshot(() => {
          this.getMylist();
      });
    }
    let bufitemList = [];
    db.collection('users').doc(this.props.userdata.uid).collection('items').where("status", "!=", "delete").orderBy("status", "desc").orderBy("dt", "desc")
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const docdat = doc.data();
          let price
          if ('minActivityAmount' in docdat.priceModule) {
            price = docdat.priceModule.minActivityAmount.value;
          }
          else if ('maxActivityAmount' in docdat.priceModule) {
            price = docdat.priceModule.maxActivityAmount.value;
          }
          else if ('minAmount' in docdat.priceModule) {
            price = docdat.priceModule.minAmount.value;
          }
          else if ('maxAmount' in docdat.priceModule) {
            price = docdat.priceModule.maxAmount.value;
          }
          const dat = {
            title: { displayTitle: docdat.pageModule.title.split("|")[0] },
            image: { imgUrl: docdat.imageModule.imagePathList[0]?docdat.imageModule.imagePathList[0].replace('https:', ''):"" },
            prices: { salePrice: { formattedPrice:  "￥" + price.toLocaleString(),value:price } },
            sellingPoints: [{ position: 4, tagContent: { tagText :(docdat.freightAmount.shipping_price ? parseInt(docdat.freightAmount.shipping_price.replace(/[^0-9]/g, '')) ? parseInt(docdat.freightAmount.shipping_price.replace(/[^0-9]/g, '')) : 0 : 0)} }],
            productId: docdat.sourceItemId,
            domain: docdat.domain ? docdat.domain : ((docdat.itemId == docdat.sourceItemId) ? "joino.me":"aliexpress.com") ,
            dt: docdat.dt,
            itemId: docdat.itemId,
            status: docdat.status,
          }
          bufitemList.push(dat);
        });
      }).then(() => {
//        bufitemList.sort((a, b) => b.dt - a.dt);
        this.setState({
          error: '',
          page: 1,
          currentCategory: { categoryId: "0" },
          SearchText:'',    
          itemList: bufitemList,
          getting:false,
        });             
      });
    this.setState({
      error: '',
      page: 1,
      currentCategory: { categoryId: "0" },
      SearchText:'', 
      getting:true,
    });
  }
  
  addfavCategory = (fav) => {
    if(fav.categoryId=='0') return
    db.collection('users').doc(this.props.userdata.uid)
    .update({
      favCategory: firebase.firestore.FieldValue.arrayUnion(fav),
    });
    let lst = this.state.favCategory?this.state.favCategory:[];
    lst = lst.concat(fav)
    this.setState(
      {
        favCategory: lst
      }
    );
  }
    
  removefavCategory = (fav) => {
    if(fav.categoryId=='0') return
    db.collection('users').doc(this.props.userdata.uid)
    .update({
      favCategory: firebase.firestore.FieldValue.arrayRemove(fav),
    });
    let lst=[]
    for (let index = 0; index < this.state.favCategory.length; index++) {
      if (fav.categoryId != this.state.favCategory[index].categoryId){
        lst.push(this.state.favCategory[index]); 
      }
    }
    this.setState(
      {
        favCategory: lst
      }
    );
  }

  searchTexthandleChange = (e) => {
    this.setState({
      SearchText:e.target.value
    });
  }

  searchbottnhandle = (e) => {
    this.setState(
      {
        itemList: [],
        page:1,
        error:'',
      }, () => {
        this.getData();
      }
    );
  }

  minPricehandleChange = (e) => {
    this.setState({
      minPrice:e.target.value
    });
  }
  maxPricehandleChange = (e) => {
    this.setState({
      maxPrice:e.target.value
    });
  }

  handleSortTypeChange = (e) => {
    this.setState({
      SortType:e.target.value,
      itemList: [],
      page:1,
      error:'',
  }, () => {
      this.getData();
    });
  }
  
  handleCategoryName = (cat) => {
    db.collection('users').doc(this.props.userdata.uid)
    .set({
      refCategory:this.state.currentCategory,
      currentCategory:cat,
    }, { merge: true });    
    this.setState(
      {
        itemList: [],
        categoryLst: [],
        refCategory:this.state.currentCategory,
        currentCategory: cat,
        page: 1,
        error: '',
      }, () => {
        this.getData();
      }
    );
  }

  userdataset = (key,value) => {
    db.collection('users').doc(this.props.userdata.uid).set({[key]: value }, { merge: true });
  }

  setRootCategory = async () => {
    if (this.state.categoryroot) {
      this.setState({
        categoryLst: this.state.categoryroot,
        favCategory: this.props.userdata.favCategory,
        itemList: [],
        currentCategory: { categoryId: "0", categoryEnName: "root", categoryName: "Top" },
        page:1,
      });    
    } else {
      const docref = await db.collection('aliCategorylist').doc('root').get();
      if (docref.exists) {
        const lst = await docref.data();
        this.setState({
          categoryroot: lst.item,
          categoryLst: lst.item,
          favCategory: this.props.userdata.favCategory,
          itemList: [],
          currentCategory: { categoryId: "0", categoryEnName: "root", categoryName: "Top" },
          page:1,
        });
      }  
    }
  }

  setStart = () => {
    if (this.props.userdata.currentCategory) {
      this.setState({
        favCategory: this.props.userdata.favCategory,
        currentCategory: this.props.userdata.currentCategory,
        itemList: [],
        page: 1,
        error: '',
        }, () => {
        this.getData();
      });
    }
    else {
      this.setRootCategory();
    }
  }

  getData = async () => {
    if (this.itemCahange) {
      this.itemCahange();
      this.itemCahange = null;
    }
    console.log('this.state.currentCategory.categoryId: ',this.state.currentCategory.categoryId);
    if (this.state.currentCategory.categoryId!="0"||this.state.SearchText) {
      // https://joino.me/functions/getitemlist/itemlist.json?uid=pjN1W1a1V5ZF09iBPcSa5Zo5xb22&trafficChannel=main&CatId=0&SearchText=sexy cute&SortType=default&page=1
      let argstr = "?trafficChannel=main"
  //    argstr += this.get(args,"categoryName") ? "&catName=" + this.get(args,"categoryName") : "";
      argstr += this.get(this.state.currentCategory,"categoryId") ? "&CatId=" + this.get(this.state.currentCategory,"categoryId") : "&CatId=0";
      argstr += this.get(this.state,"SearchText") ? "&SearchText=" + this.get(this.state,"SearchText") : "";
      argstr += "&ltype=wholesale";
      argstr += this.get(this.state,"isFreeShip") ? "&isFreeShip=" + this.get(this.state,"isFreeShip") : "";
      argstr += this.get(this.state,"isFavorite") ? "&isFavorite=" + this.get(this.state,"isFavorite") : "";
      argstr += this.get(this.state,"SortType") ? "&SortType=" + this.get(this.state,"SortType") : "&SortType=default";
      argstr += this.get(this.state,"minPrice") ? "&minPrice=" + this.get(this.state,"minPrice") : "";
      argstr += this.get(this.state,"maxPrice") ? "&maxPrice=" + this.get(this.state,"maxPrice") : "";
      argstr += this.get(this.state,"page") ? "&page=" + this.get(this.state,"page") : "&page=1";
      argstr += "&isrefine=y";
      const docname = await this.sha256(argstr);
      console.log(docname);

/*
      const docref = await db.collection('itemlist').doc(docname).get()
      if (docref.exists) {
        const dat = docref.data();
        const now = new Date();
        console.log("now - dat.dt.toDate(): " + (now - dat.dt.toDate()))
        if ((now - dat.dt.toDate()) / 1000 / 60 / 60 / 24 < 1) {
          this.setData(dat.listdata,dat.refineCategory[0]);
          return        
        }
      }
*/
//      const url = "/functions/getitemlist/itemlist.json" + argstr + "&uid=" + this.props.userdata.uid + "&docname=" + docname//cookieをuserに保存しているのでサイトを隠蔽できない
      const url = "/functions/getaliitemlist/itemlist.json" + argstr + "&uid=" + this.props.userdata.uid + "&docname=" + docname//cookieをuserに保存しているのでサイトを隠蔽できない
      console.log("fetch url:",url)
      this.setState({error: "no_data"});
      fetch(url)
      .then(
        (result) => {
          if (result.status == 200) {
            console.log("getData stats 200");
            return result.json()
          }
          else {
            this.setState({
              error: result.body,
            });            
          }
        },
        // 補足：コンポーネント内のバグによる例外を隠蔽しないためにも
        // catch()ブロックの代わりにここでエラーハンドリングすることが重要です
        (error) => {
          this.setState({
            error:error,
          });            
        }
      )
      .then((jsondata) => {
        /* data = {
            u'items':items,
            u'refineCategory':refineCategory,
            u'categoryName':categoryName,
            u'categoryId':categoryId,
            u'dt':dt.now(UTC),
            u'request_args':args,
            u'categoryUrl':categoryUrl,
            u'listdata':listdata,
            u'allcookies':allcookies
        }*/  
        try {
          if (jsondata.listdata.success==true||jsondata.listdata.success=="true") {
            this.setData(jsondata.listdata,jsondata.refineCategory[0]?jsondata.refineCategory[0]:this.state.categoryroot);
          } else {
            this.setState({
              error: "data-error",
              getting:false,
            });              
          }
        } catch (error) {
          console.error('There has been a problem with your jsondata:', error);
          this.setState({
            error: "data-error",
            getting:false,
          });            
        }
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        this.setState({
          error: error,
          getting:false,
        });
      }); 
    }
  }
  
  //値を取得
  componentDidMount = () => {
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true);
    /*
    process.nextTick(() => {
      const elem = this.topParts;
      console.log("elem.clientHeight: ", elem.clientHeight)
      this.setState({
          topPartsHeight: elem.clientHeight
      })
    })
    */
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.getMylist();
      } else {        
        this.setState({
          error:"not login"
        });
      }
    });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true)
  }

  watchCurrentPosition() {
    const pos = this.topParts.clientHeight + this.topParts.getBoundingClientRect().top; //clientHeight: 42710  document.getElementById("scrolldiv").getBoundingClientRect().top
    const page = this.state.page;
    if (pos > window.itemlistrepos) {
      if (!this.state.accordionExpanded) {
        this.setState({ accordionExpanded: true })
      }
    }
    else {
      if (this.state.accordionExpanded) {
        this.setState({ accordionExpanded: false })
      }
    }
    window.itemlistrepos = pos;
    if (pos < 10000 && this.state.getting==false) {
      console.log('pos: ', pos)
      this.setState({
        getting: true,
        page:page + 1,
      }, () => {
        this.getData();
      });
    }
  }

  render() {
    // Material-ui関連
    const { classes } = this.props;
    const Items = this.state.itemList? this.state.itemList:[];
    const SearchText = this.state.SearchText ? this.state.SearchText : '';
    const minPrice = this.state.minPrice ? this.state.minPrice : '';
    const maxPrice = this.state.maxPrice ? this.state.maxPrice : '';
    const SortType = this.state.SortType ? this.state.SortType : 'default';
    let renderCards = [];
    let itemCards = [];
    let emptyCards = [];
    const ItemsLength = Items ? Items.length : 0;
    let catmenu = [<MenuItem categoryId="0" icon="home" categoryEnName="Root" categoryName="" iconOnClick={this.setRootCategory} />];
    catmenu = catmenu.concat([<MenuItem categoryId="0" icon="mylist" categoryEnName="mylisty" categoryName="MyList"  iconOnClick={()=>this.getMylist()} categoryNameClick={()=>this.getMylist()}/>]);
    if (this.state.pathCategories) {
      catmenu = catmenu.concat([<MenuItem categoryId={this.state.pathCategories.slice(-1)[0].categoryId} icon="upper" categoryEnName={this.state.pathCategories.slice(-1)[0].categoryEnName} categoryName={this.state.pathCategories.slice(-1)[0].categoryName}  iconOnClick={(fav) => this.removefavCategory(fav)} categoryNameClick={(cat) => this.handleCategoryName(cat)}/>]);
    }
    //this.state.categoryLst.pathCategories.slice(-1)[0]
    if (this.state.currentCategory) {
      if (!this.state.favCategory || !this.state.favCategory.some((u) => u.categoryId == this.state.currentCategory.categoryId)) {
        catmenu = catmenu.concat([<MenuItem categoryId={this.state.currentCategory.categoryId} icon="curdef" categoryEnName={this.state.currentCategory.categoryEnName} categoryName={this.state.currentCategory.categoryName} iconOnClick={(fav) => this.addfavCategory(fav)} />]);
      }
      else {
        catmenu = catmenu.concat([<MenuItem categoryId={this.state.currentCategory.categoryId} icon="curfav" categoryEnName={this.state.currentCategory.categoryEnName} categoryName={this.state.currentCategory.categoryName} iconOnClick={(fav) => this.removefavCategory(fav)} />]);   
      }
    }
    if (this.state.favCategory) {
      for (let index = 0; index < this.state.favCategory.length; index++) {
        const e = this.state.favCategory[index];
        if (e.categoryId != this.state.currentCategory.categoryId) {
          catmenu.push(<MenuItem categoryId={e.categoryId} icon="fav" categoryEnName={e.categoryEnName} categoryName={e.categoryName} iconOnClick={(fav) => this.removefavCategory(fav)} categoryNameClick={(cat) => this.handleCategoryName(cat)}/>)          
        }
//        catmenu.push(<MenuItem categoryId={e.categoryId} icon="fav" categoryEnName={e.categoryEnName} categoryName={e.categoryName} iconOnClick={(fav) => this.removefavCategory(fav)} />)
      }
     }
    if (this.state.categoryLst) {
//      console.log("currentCategory.categoryId: " + this.state.currentCategory.categoryId)
      for (let index = 0; index < this.state.categoryLst.length; index++) {
        const e = this.state.categoryLst[index];
//        console.log("e.categoryId: " + e.categoryId);
        if (!this.state.favCategory || !this.state.favCategory.some((u) => u.categoryId == e.categoryId)&&e.categoryId!=this.state.currentCategory.categoryId) {
          catmenu.push(<MenuItem categoryId={e.categoryId} icon="def" categoryEnName={e.categoryEnName} categoryName={e.categoryName} iconOnClick={(fav) => this.addfavCategory(fav)}  categoryNameClick={(cat) => this.handleCategoryName(cat)}/>)          
//          catmenu.push(<MenuItem categoryId={e.categoryId} icon="def" categoryEnName={e.categoryEnName} categoryName={e.categoryName} iconOnClick={(fav) => this.addfavCategory(fav)} />)          
        }
      }
    }
    //商品
    for (let index = 0; index < ItemsLength; index++) {
      const title = Items[index].title?Items[index].title.displayTitle:'';
      const imgUrl = Items[index].image?"https:" + Items[index].image.imgUrl.split('_220x220')[0]:"";
      const price = Items[index].prices.salePrice ? parseInt(Items[index].prices.salePrice.formattedPrice.replace(/[^0-9]/g, ''))  : 0;
      const price2 = parseInt(price * this.props.userdata.MarkupRatio, 10);
      const shipping = Items[index].sellingPoints ? Items[index].sellingPoints.map((e) => { return (e.position && e.position == 4) ? e.tagContent.tagText : "" }) : [""];
      const shippingprice = shipping.toString().replace(/[^0-9]/g, '');
      const shippingprice2 = shippingprice ? parseInt(shippingprice):0;
      const trueprice = '￥ ' + (price2 + shippingprice2).toLocaleString();
      const href = "/itemedit/" + Items[index].productId + "?domain=" + Items[index].domain;
      const uid = this.props.userdata.uid;
      const itemid = Items[index].itemId;
      const status = Items[index].status?Items[index].status:"";
//      if ((price2 + shippingprice2) > 150) {
        itemCards.push(<Item empty={false} title={title} imgUrl={imgUrl} price={trueprice} href={href} uid={uid} itemid={itemid} getMylist={this.getMylist} deletable={itemid} status={status}/>);
//      }
    }

    renderCards.push(itemCards);

    // 読み込み中はロード画面にする
    const errors = () => {
      switch (this.state.error) {
        case 'data-error':
          return (
            <p>データがありません</p>
          )
        case 'not login':
          return (
            <p>ログインが必要です</p>
          )
        case 'no_data':
          return (
            <p>
              <CircularProgress /> <p>データを取得しています</p>
            </p>
          )
        default:
          return (
            this.state.error?<p>{this.state.error}</p>:""
          )
      }
    }

    /*
    if (this.get(this.state, "error")) {
      return (
        <div class="overflow">
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
              {errors()}
          </Typography>
        </Paper>
      </div>
      )
    }
*/
    return (
      <>
        <Paper style={{ position: 'fixed' }}>
          {/*
        <Accordion
          bposition="fixed"
          defaultExpanded={true}
          expanded={this.state.accordionExpanded}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header" className={classes.accordionSummary}
          style={{height: '15px','min-height': '15px'}}
          min-height='15px'
          height='15px'
          >
        </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <div className={classes.searchbox}>
              　<TextField
                id="SearchText"
                name="SearchText"
                label="Search"
                helperText=""
                variant="outlined"
                autoComplete='off'
                msg={console.log('SearchText:', SearchText)}
                value={SearchText}
                onChange={e => this.searchTexthandleChange(e)}
                onKeyPress={e => {
                  if (e.key == 'Enter') {
                    this.getData();
                    }
                  }
                } 
                required="true"
                InputProps={{
                  classes: {
                    input: classes.resize, 
                  },
                }}
                InputLabelProps={{ shrink: true }}
                className={classes.serchtext}
                />
              <div><Typography className={classes.inlinetext}>　価格：</Typography></div>
              <TextField
                id="minPrice"
                name="minPrice"
                label="min"
                helperText=""
                variant="outlined"
                autoComplete='off'
                value={minPrice}
                onChange={e => this.minPricehandleChange(e)}
                onKeyPress={e => {
                  if (e.key == 'Enter') {
                    this.getData();
                    }
                  }
                } 
                required="true"
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                className={classes.pricetext}
                />
              <div><Typography className={classes.inlinetext}> - </Typography></div>
              <TextField
                id="maxPrice"
                name="maxPrice"
                label="max"
                helperText=""
                variant="outlined"
                autoComplete='off'
                value={maxPrice}
                onChange={e => this.maxPricehandleChange(e)}
                onKeyPress={e => {
                  if (e.key == 'Enter') {
                    this.getData();
                    }
                  }
                } 
                required="true"
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                className={classes.pricetext}
                />
                <Select
                  labelId="SortType"
                  id="SortType"
                  value={SortType}
                  onChange={e => this.handleSortTypeChange(e)}
                  displayEmpty
                  className={classes.selectSortType}
                >
                  <Mi value={'default'} selected={'default' === SortType } onClick={e => this.handleSortTypeChange(e)}>オススメ</Mi>
                  <Mi value={'create_desc'} selected={'create_desc' === SortType} onClick={e => this.handleSortTypeChange(e)}>最新</Mi>
                  <Mi value={'total_tranpro_desc'} selected={'total_tranpro_desc' === SortType} onClick={e => this.handleSortTypeChange(e)}>販売数</Mi>
                  <Mi value={'price_asc'}selected={'price_asc' === SortType} onClick={e => this.handleSortTypeChange(e)}>安い</Mi>
                  <Mi value={'price_desc'}selected={'price_desc' === SortType} onClick={e => this.handleSortTypeChange(e)}>高い</Mi>
                </Select>
              <Button
                  className={classes.searchbottn}
                  onClick={e => {
                  this.searchbottnhandle();
                }}
              >
              <Search
                className={classes.searchbottn}
                onClick={e => {
                  this.searchbottnhandle();
                }}
              />
              </Button>
            </div>
         <div className={classes.categlybox}>     
          {catmenu}
        </div>
        </AccordionDetails>
        </Accordion>
              */}
      </Paper>
        {this.get(this.state, "error")?<div class="overflow">{errors()}</div>:""}

        <div className={classes.textcenter} ref={(div) => {this.topParts = div }} id="scrolldiv">
          {renderCards}         {/* 無限スクロールで表示する項目 */}
          {this.state.getting?<CircularProgress />:''}
        </div>
      </>
    );
  }
}

// Material-ui関連
ItemList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(ItemList);