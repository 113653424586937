import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom"
import firebase, { db } from './Firebase'
//import firebase from 'firebase/app';
//import { FieldValue } from '@google-cloud/firestore';
//import 'firebase/firestore';
//const admin = require('firebase-admin');
import "firebase/auth";

import CircularProgress from '@material-ui/core/CircularProgress';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CommonDialog from './components/CommonDialog3';


// スタイル
const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      margin: 10,
    },
    textLeft: {
      textAlign: 'left',
    },
    textCenter: {
      textAlign: 'center',
    },
    paragraph: {
      marginTop: 10,
      marginBottom: 10,
    },
});



class AuthRoute extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // ログインチェックが終わるまで、ローディングを表示.
      loading : true,
      // ログインチェック結果.
      isAuthenticated: false,
      codeerror:false,
    }
  }


  codesubmit = (code) => {
    const docRef = db.collection("InvitationCode").doc(code);
    docRef.get().then((doc) => {
      //ドキュメントの存在確認
      if (doc.exists) {
        const docdata = doc.data()
        //docdata.counterは必須項目
        if (docdata.counter < docdata.max) {
            db.collection('InvitationCode').doc(code).update({
              counter: firebase.firestore.FieldValue.increment(1),
              invitees: firebase.firestore.FieldValue.arrayUnion(this.state.userdata.uid),
            }).then(this.codesuccess(code));
        } else {
          this.setState({
            codeerror: true,
          });  
        }
      } else {
        this.setState({
          codeerror: true,
        });        
      }
    });
  }

  codesuccess = (code) => {
    let userdata = this.state.userdata
    userdata.code = code;
      //アカウントをリンクして複数のproviderDataを持つ時はphotoURLをgetuserphotoURLでTwitter用に加工すること        
    const docRef = db.collection("users").doc(userdata.uid);
    docRef.set(userdata);
    this.setState({
      userdata: userdata,
      codeerror:false,
    });  
  }

  getuser = async (user) => {
    const docRef = db.collection("users").doc(user.uid);
    const doc = await docRef.get();
    let userdata = {}
    //ドキュメントの存在確認
    if (doc.exists) {
      userdata = doc.data()
    }
    else {
      userdata = {
        uid: user.uid,
        ambassadordisplayName : user.displayName,
        displayName: user.displayName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        photoURL: user.photoURL,
        providerId: user.providerId,
        providerData: Object.assign({}, user.providerData),
        MarkupRatio: 2,
        code:'',
        dt:firebase.firestore.FieldValue.serverTimestamp(),
        //アカウントをリンクして複数のproviderDataを持つ時はphotoURLをgetuserphotoURLでTwitter用に加工すること        
      }
      docRef.set(
        userdata
      );
    }
    this.setState({
      signinCheck: true,
      signedIn: true,
      userdata: userdata
    });
  }


  componentDidMount = () => {
    //mountされてる
    this._isMounted = true;

    //ログインしてるかどうかチェック
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            //してる
          this.getuser(user);
        } else {
            console.log("not login")
            //してない
            if (this._isMounted) {
                this.setState({
                    signinCheck: true,
                    signedIn: false,
                });
            }
        }
    })
}


getuserphotoURL(user) {
    if (user.probiderData[0].providerId == "twitter.com")
        //providerがTwitterの時圧縮されていないアイコンを返す
        return user.probiderData[0].photoURL.replace("_normal","")
    
}

componentWillUnmount = () => {
    this._isMounted = false;
}

handleOnSubmit = () => {
     <Route {...rest} render={() => {
         return <CircularProgress />
     }}
     />
}
    
render() {
    const { classes } = this.props;

    // ルーティング情報を取得.
    const { component : Component, ...rest } = this.props

    // ログインチェック状態を取得.
    const { signinCheck, signedIn } = this.state

    // ログインチェック前なら、ローディングを表示.
    if (!signinCheck) {
        return (
            <CircularProgress />
            );
    }

    // ログインチェック後はルーティング処理を行う.
    return (
      <Route {...rest} render={(classes) => {
        // 未ログインなら、ログイン画面にリダイレクト.
        if (!signedIn) {
            const uiConfig = {
                signInFlow: 'redirect', //'redirect' 'popup'
                signInSuccessUrl: this.props.location.pathname,
                signInOptions: [
                    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    //firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                ],
            };
            return (
                <div className="container">
                <div className={classes.root} >
                  <h2>ログイン</h2>
                  <div className={classes.textLeft}>
                    <Paper className={classes.root} elevation={1}>
                      <Typography variant="headline" component="h3">
                        joinoのアカウントにはSNSアカウントが必要です<br />
                        SNSアカウントでログインしてください<br />
                        ログイン後このメッセージがまた表示された時はこのまま少しお待ち下さい
                      </Typography>
                      <form onSubmit={this.handleOnSubmit}>
                        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                      </form>
                    </Paper>
                  </div>
                </div>
              </div>        
            )
        }
        // ログイン済なら、指定されたコンポーネントを表示.
        //console.log('oya:::' + JSON.stringify(this.state.userdata) )
        if ((!this.state.userdata.code || this.state.userdata.code == '') && this.state.codeerror == false) {
          return (
            <CommonDialog
              msg={"招待コードを入力してください"}
              label={"code"}
              helperText={"半角"}
              submit={(code) => this.codesubmit(code)}
            />
          );
        }
        else if (this.state.codeerror == true){
          return(
          <CommonDialog
            msg={"招待コードが無効です。有効なコードを入力してください"}
            label={"code"}
            helperText={"半角"}
            submit={(code) => this.codesubmit(code)}
          />);          
        } else {
          return <Component userdata={this.state.userdata} {...this.props} />          
        }
      }}
      />
    )
  }
}

export default AuthRoute