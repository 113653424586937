import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});


class PrivacyPolicy extends React.Component {

  render() {

    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <h2>個人情報保護方針(プライバシーポリシー)</h2>
        <div className={classes.textLeft}>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            個人情報保護方針(プライバシーポリシー)
            </Typography>
            <Typography component="p">
              ジョイノ（以下、当社）は、当社サービスを利用するお客様や取引関係者等、弊社業務と関わりのあるすべての関係者の皆様の個人情報の重要性を認識し、その保護を徹底するために、下記の個人情報保護方針を定め、個人情報の適切な取り扱いに取組んでまいります。
              <ul>
	              <li>当社は、個人情報の保護に関する法律その他関係法令及びガイドライン等の社会規範を遵守し、個人情報の保護の実践に努めてまいります。</li>
	              <li>当社は、個人情報を取得させていただく場合は、利用目的をできる限り特定するとともに、利用目的をあらかじめ公表したうえで、適法かつ公正な手段によって、必要な範囲の個人情報を取得させていただきます。</li>
	              <li>当社は、個人情報を特定した利用目的の範囲内でのみ利用します。定めた利用目的の範囲を超えて取り扱いません。</li>
	              <li>当社は、法令が認める場合を除き、当該個人情報によって識別されるご本人（以下、ご本人）の同意を得ずには、第三者への個人情報の提供・開示等はいたしません。</li>
	              <li>当社は、個人情報の保護を実践するため、個人情報の取得・提供・取扱いの委受託等に関する外部との手続を、適切に整備・運用します。</li>
	              <li>当社は、保有する個人情報について、ご本人の個人情報開示・修正・削除依頼に対しては別途定める手続に則って合理的な範囲ですみやかに対応させていただきます。</li>
	              <li>当社は、個人情報の漏えい、滅失またはき損などの危険を防止および是正するための、社内規程や責任体制を定め、合理的で適正な安全対策を講じます。</li>
	              <li>当社は、当社に対し個人情報の取扱いに関する苦情及びご相談がある場合は、適切かつ迅速に対応します。</li>
	              <li>当社は、個人情報保護に関するマネジメントシステム（本方針、個人情報保護に関する規程･規則等を含む）を構築し、これを従業員その他関係者に周知徹底させて実施・維持するとともに、継続的に改善します。</li>
	              <li>個人情報保護・取扱いの考え方及び方法については適宜改善・改良を行います。従いまして本方針は予告無しに変更されることがあります。</li>
              </ul>
              制定日：2021年4月13日
              <br />
              改定日：2021年6月12日
              <br />
              <br />
              joino
              <br />
              代表 鵜飼 浩司
            </Typography>
          </Paper>
        
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            個人情報の利用目的
            </Typography>
            <Typography component="p">
            当社は、保有する個人情報の利用にあたっては、以下のとおり利用目的を定め、法令により認められる場合を除き、その範囲内で個人情報を取り扱います。なお、全ての保有個人データ（開示対象個人情報）の利用目的も以下のとおりです。なお、以下の記載に拘わらず、法令その他の規範に基づく届出または報告を行うため、または、当社の事業を遂行する上で必要な範囲で当社からのアンケート、通知その他の連絡を行うために、保有する個人情報を利用することがあります。また、これらの個人情報の取扱いを第三者に委託することがあります。それ以外の目的で利用する場合は、個人情報をご提供いただく際に、予めその旨を明示します。
              <ul>
                <li>
                  当社が運営する一般の方を対象としたサービスの利用者の個人情報
                  <ul>
                    <li>弊社の商品、サービス及び関連情報を提供または改善、お問い合わせに対する回答のため</li>
                    <li>インターネットサイトの会員認証及び利用者への連絡</li>
                    <li>当社が提供するその他のサービスに関する情報提供やプレゼントなどの配送物をお届けする、もしくはその告知をするため</li>
                    <li>マーケティングデータを集計・分析するため</li>
                    <li>アンケート調査の実施や広告の提供をするため</li>
                    <li>なりすましなど不正利用の調査をするため</li>
                    <li>joinoサービス全般の企画・運営のため</li>
                    <li>その他、上記各号に関連または附帯する目的のため</li>
                  </ul>
                </li>
                <li>
                  当社取引先・提携企業及び団体従業員に関する個人情報の利用目的
                  <ul>
                  <li>連絡・商談・打ち合わせ・契約の履行・お問い合わせやご依頼への対応</li>
                  <li>法令その他の規範に基づく届出・報告</li>
                  <li>前各号を遂行する上で必要な範囲における第三者への提供</li>
                  </ul>
                </li>
                <li>
                  当社広報関連窓口・お客様窓口・および対外的窓口である当社の役員・従業員（パート・アルバイト及び派遣社員を含む）・当社のインターネットサイトに対する相談・連絡等のアクセスを行った方々に関する個人情報の利用目的
                  <ul>
                    <li>ご相談・ご連絡の内容の検討・調査及び対応</li>
                    <li>アクセスに付随する情報及び履歴の管理</li>
                  </ul>
                </li>  
                <li>
                  株主の方々に関する個人情報の利用目的
                  <lu>
                    <li>会社法等の法令に定められた義務の履行及び株主権利のご行使への対応</li>
                    <li>事業報告書その他の配布物の送付</li>
                  </lu>
                </li>
                <li>                 
                  採用応募者の方々に関する個人情報の利用目的
                  <lu>
                    <li>採否の検討・決定及び連絡</li>
                  </lu>
                </li>
                <li>
                  役員、従業員（過去に役員・従業員であった者を含む）又はそれらの家族の方の個人情報
                  <ul>
                    <li>当社における人員の適正配置その他の人事管理、給与支払その他の労務管理</li>
                    <li>福利厚生、安全衛生管理</li>
                    <li>教育・研修</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            匿名情報の取扱いについて
            </Typography>
            <Typography component="p">
            当社サービスの利用者が当社Webページを閲覧した際やスマートフォンまたはタブレット向けアプリを利用した際、当社発行のHTML形式のメールマガジンを閲覧した場合などに、利用者に関する匿名情報（IPアドレス、サイト滞在時間、位置情報、どのページが読まれたか等の情報）がWebサーバに自動的に記録されます。この情報は以下のような目的のために利用されます。
              <ul>
                <li>サーバで発生した問題の原因を突き止め、解決するため</li>
                <li>サイト管理のため</li>
                <li>メールマガジンの購読率調査および関連するコンテンツ管理のため</li>
                <li>お客様に適した機能または情報（広告を含む）を提供するため</li>
                <li>利用者が当社に個人を特定できるような情報を提供しない限り、この情報のみを使用して当社が利用者個人を特定することはできません。なお、当社はこうした匿名情報を取得・収集しやすくするために、たとえば、極めてサイズの小さな透明画像をWebページに埋め込んで利用することがあります。</li>
              </ul>
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              決済業務に関する個人情報クレジットカード情報について
            </Typography>
            <Typography component="p">
              当社は、引き落しおよびクレジットカードによる決済業務を、決済代行サービス会社に委託しております。その際、当社からこれらの業務委託先に必要な範囲で個人情報を提供することがあります。その場合、当社は、業務委託先との間で取扱いに関する契約を結ぶ等、適切な監督を行います。
              <br/>
              当社のシステムを介して入力したクレジットカード情報（クレジットカード番号、有効期限、セキュリティコード）は、決済代行サービス会社が保持しており、当社はクレジットカード情報を一切保持しません。
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
              Cookieに関するポリシー
            </Typography>
            <Typography component="p">
              <ol>
                <li>
                Cookieについて
                  <p>
                    Cookieとは、Webページを利用したときに、ブラウザとサーバとの間で送受信した利用履歴や入力内容などを、利用者のコンピュータに保存しておく仕組みです。次回、同じページにアクセスすると、Cookieの情報を使って、ページの運営者は利用者ごとに表示を変えたりすることができます。
                    利用者がブラウザの設定でCookieの送受信を許可している場合、Webサイトは、ユーザーのブラウザからCookieを取得できます。なお、利用者のブラウザは、プライバシー保護のため、そのWebサイトのサーバが送受信したCookieのみを送信します。利用者が設定を変更することにより、Cookieの機能を停止することも可能ですが、その場合は一部のサービスをご利用できなくなることがありますのでご注意ください。
                  </p>
                </li>
                <li>Cookieの設定について
                  <p>
                  利用者は、Cookieの送受信に関する設定を「すべてのCookieを許可する」、「すべてのCookieを拒否する」、「Cookieを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります。Cookieに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。 すべてのCookieを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。
                  </p>
                </li>
                <li>
                  当社がCookieを使用して行っていること
                  <p>
                    以下の目的のため、当社はCookieを利用しています。
                    <ul>
                      <li>利用者が認証サービスにログインされるとき、保存されている利用者の登録情報や端末情報を参照して、利用者ごとにカスタマイズされたサービスを提供できるようにするため。</li>
                      <li>利用者が興味を持っている内容や、当社のサイト上での利用状況をもとに、最も適切な広告を他社サイト上で表示するため。</li>
                      <li>当社のサイトの利用者数やトラフィックを調査するため。</li>
                      <li>当社のサービスを改善するため。</li>
                    </ul>
                  </p>
                </li>
              </ol>
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            第三者配信事業者の広告配信について
            </Typography>
            <Typography component="p">
            Google、Yahoo! JAPANを含む第三者配信事業者によりインターネット上のさまざまなサイトに当社の広告が掲載されています。 Google、Yahoo! JAPANを含む第三者配信事業者は Cookie等の識別情報を使用して、当社Webサイトへの過去のアクセス情報に基づいて広告を配信します。ただし、これらの識別情報やアクセス情報には個人を識別できる情報は一切含んでおらず、広告配信以外の目的で利用することはありません。
            <br/>＜Google によって提供されるディスプレイ広告に関連するサービスの利用について＞
            当社は、当社ウェブサイトの利用状況に応じて、Google AdWords広告を表示します。また、当社ウェブサイトの利用状況を把握するためにGoogle アナリティクスを利用し、Google アナリティクスによって提供される広告サービス（Google アナリティクス リマーケティング、Google ディスプレイ ネットワークの表示回数レポート、Google アナリティクスのユーザーの分布とインタレスト カテゴリに関するレポート、Google アナリティクスを使用して広告 Cookie と匿名 ID を使ったデータを収集する統合的なサービス）を利用します。このため、Google アナリティクスの標準的なデータに加え、Google 広告Cookieと匿名IDを使ったトラフィックデータを収集しています。詳細については、<a href='https://marketingplatform.google.com/about/analytics/terms/jp/'>Google アナリティクス利用規約</a>、および<a href='https://policies.google.com/privacy?gl=jp'>Google アナリティクスのプライバシーポリシー</a>をご確認ください。
            Google によるCookieの使用を無効にする場合は、<a href='https://support.google.com/ads/answer/2662922?hl=ja'>Google 広告のオプトアウトページ</a>にアクセスして、GoogleによるCookieの使用を無効にすることができます。
            <br/>＜Yahoo! JAPANによって提供される行動ターゲティング広告の利用について＞
              当社は、当社ウェブサイトの利用状況に応じて、Yahoo! JAPANおよびYahoo! JAPANのパートナーサイトに広告を表示します。詳細は<a href='https://btoptout.yahoo.co.jp/optout/index.html'>Yahoo! JAPANが配信する行動ターゲティング広告について</a>をご確認下さい。なお、Yahoo! JAPANがCookieを通じて取得する情報の利用については<a href='https://about.yahoo.co.jp/common/terms/#cf2nd'>こちら</a>を御参照下さい。 Yahoo! JAPANによるCookieの使用を無効にする場合は、<a href='https://btoptout.yahoo.co.jp/optout/index.html'>Yahoo! JAPANの配信する行動ターゲティング広告のオプトアウトページ</a>にアクセスして、Yahoo! JAPANによるCookieの使用を無効にすることができます。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            メールマガジン等の各種情報の配信停止手続きについて
            </Typography>
            <Typography component="p">
            当社からのメールマガジン等の各種情報の配信停止を希望される場合は、配信停止されたいメールマガジンの末尾に記載の手続きを行なう、もしくは当社が運営するWebサイト上から会員登録情報の設定を変更することにより、当該情報の配信を停止することができます。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            営業譲渡について
            </Typography>
            <Typography component="p">
            当社は、当社事業の発展を常に追求しています。これらの目的を成し遂げる際、当社は事業を買収・売却する、あるいは他の企業により買収される可能性があります。当社が事業を売却する場合、当社は皆様に提供するサービスを継続するため、またはその他の事業上の目的のために、皆様の情報の全部・または一部を移転させることがあります。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            個人情報の開示に関して
            </Typography>
            <Typography component="p">
              請求いただける内容
              <br/>
              当社では、個人情報の保護に関する法律（以下「法」といいます）に基づき、当社が保有する個人情報に関して、ご本人またはその代理人からの次のご請求（以下総称して「開示等の求め」といいます）を受け付けいたします。
              <ul>
                <li>「利用目的の通知」の請求（法第24条第2項に基づく請求）</li>
                <li>「開示」の請求（法第25条第1項に基づく請求）</li>
                <li>「内容の訂正又は追加」の請求（法第26条第1項に基づく請求）</li>
                <li>「利用の停止又は消去」の請求（法第27条第1項及び第2項に基づく請求）</li>
                <li>「第三者への提供の停止」の請求（法第27条第2項に基づく請求）</li>
              </ul>
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            個人情報の開示等手続きについて
            </Typography>
            <Typography component="p">
              「開示等の求め」の申出先
              <br/>
              「開示等の求め」の手続きを行われる方は、下記のお問合せ窓口にご連絡ください。その後、郵送または電子メールで当社より所定の請求書を送付・送信いたしますので、所定の事項を全てご記入の上、下記の「本人確認のための書類」を添付し、下記の住所宛にご郵送ください。その際、封筒に朱書きで「開示等請求書類在中」とお書き添えください。なお、電話、FAX、E-MAIL、面会等の方法による請求は受け付けしておりません。また、下記の当社の個人情報相談窓口宛郵送によるもののみ受け付けしており、当社各部門の社員等では受け付けしておりませんので、予めご了承ください。
              <br/>
              ■住所
              <br/>
              〒500-8413
              <br/>
              岐阜県岐阜市加納東陽町28-8
              <br/>
              ジョイノ個人情報お問合せ窓口
              <br/>
              本人確認のための書類
              <br/>
              運転免許証またはパスポートのコピー
              <br/>
              住民票の写し（コピー不可）
              <br/><br/>
              代理人による「開示等の求め」
              <br/>
              本人が未成年者または成年被後見人である場合の法定代理人もしくは「開示等の求め」をすることにつき本人が委任した代理人による請求の場合は、前項の書類に加えて、下記の書類（ＡまたはＢ）を同封してください。
              <br />
              A.法定代理人の場合
              <br />
              当社所定の請求書
              <br />
              法定代理権があることを確認するための書類
              <br />
              （戸籍謄本、親権者の場合は扶養家族が記入された保険証のコピーも可）
              <br />
              法定代理人の「本人確認のための書類」
              <br /><br />

              B.委任による代理人の場合
              <br />
              委任状（本人が自筆で記入し実印が押印されているもの）
              <br />
              本人の印鑑証明書
              <br />
              代理人の「本人確認のための書類」
            </Typography>
          </Paper>

          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            「開示等の求め」の手数料及び徴収方法
            </Typography>
            <Typography component="p">
            「利用目的の通知」の請求または「開示」の請求については、1回の請求ごとに、500円の手数料が必要となります。500円分の郵便切手を請求書類に同封してください（なお、手数料の額は、予告なく変更させていただくことがございますので、予めご了承ください）。
              <br />
             ※手数料が不足している場合および手数料が同封されていない場合は、その旨ご連絡申し上げますが、所定の期間内にお支払いがない場合は、開示の求めがなかったものとして対応させて頂きます。
              <br />
             「開示等の求め」に対する回答方法
              <br />
              請求された方の請求書記載住所宛に書面にてご回答申し上げます。なお、回答書は、請求の受付から1か月以内を目処に発送いたしますが、事情により遅れることがございますので、予めご了承ください。
              <br />
              「開示等の求め」に関して取得した個人情報の「利用目的」
              <br />
              「開示等の求め」にともない取得した個人情報は、開示等の求めに必要な範囲内において取り扱うものとさせて頂きます。
              <br />
              提出頂いた書類は、その回答が終了した後、2年間保存し、その後廃棄させて頂きます。
              <br />
              <br />
            「開示等の求め」に応じられない事由について
              <br />
              次に定める場合は、「開示等の求め」に応じられない場合があります。「開示等」の求めに応じられない場合は、その旨、理由を付記して通知申し上げます。また、この場合についても手数料（同金額）の返還はいたしかねます。
              <br />
              ご本人または代理人の本人確認ができない場合
              <br />
              代理人による請求に際して、代理権が確認できない場合
              <br />
              所定の請求書類に不備があった場合
              <br />
              開示の求めの対象が保有する個人データに該当しない場合
              <br />
              本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
              <br />
              当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              <br />
              他の法令に違反することになる場合
              <br />
              保有個人データに関する「利用目的の通知」の請求について、当社が公表している利用目的（上記「個人情報の利用目的」）により、ご本人様が識別される保有個人データの利用目的が明らかな場合
              <br />
              保有個人データに関する「内容の訂正、追加又は削除」の請求について、当該請求が、1）ご本人様が識別される保有個人データの内容が事実でないという理由によってなされたものでない場合、又は2）「内容の訂正、追加又は削除」に関して他の法令の規定により特別の手続が定められている場合
              <br />
              保有個人データに関する「利用の停止又は消去」の請求について、当該請求が、1）ご本人様が識別される保有個人データが法第16条の規定に違反して取り扱われているという理由若しくは法第17条の規定に違反して取得されたものであるという理由によってなされたものでない場合、2）請求に理由があることが判明しない場合、又は3）保有個人データの「利用の停止又は消去」に多額の費用を要する場合その他の利用停止を行うことが困難な場合であって、ご本人様の権利利益を保護するため必要なこれに代わるべき措置をとる場合
              <br />
              保有個人データに関する「第三者への提供の停止」の請求について、当該請求が、1）ご本人様が識別される保有個人データが法第23条第1項の規定に違反して第三者に提供されているという理由によってなされたものでない場合、2）請求に理由があることが判明しない場合、又は3）保有個人データの「第三者への提供の停止」に多額の費用を要する場合その他の第三者への提供の停止を行うことが困難な場合であって、ご本人様の権利利益を保護するため必要なこれに代わるべき措置をとる場合
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            お問合せ窓口について
            </Typography>
            <Typography component="p">
              当社の個人情報の取扱いに関するお問合せにつきましては、下記までお申し出ください。
              <br />
              お手紙による場合
              <br/>
              〒500-8413
              <br/>
              岐阜県岐阜市加納東陽町28-8
              <br/>
              JOINO個人情報お問合せ窓口
              電話：050-1520-9670
              e-mail:info01@joino.me
            </Typography>
          </Paper>          
        </div>
      </div>
    );
  }
}

// Material-ui関連
PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(PrivacyPolicy);