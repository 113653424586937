import React from 'react';
//import { Link } from 'react-router-dom'

// These samples are intended for Web so this import would normally be
// done in HTML however using modules here is more convenient for
// ensuring sample correctness offline.
import firebase from "firebase/app";
import "firebase/auth";
const auth = firebase.auth();

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';

import { FiLogOut } from 'react-icons/fi';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  
  },
  twittercolor: {
    'background-color': '#55acee',
  },
  providerbotton: {
    'margin': '10px auto',
    'font-weight': '500',
    'font-size': '14px',
    'font-family': 'Roboto,Helvetica,Arial,sans-serif',
    'height': 'auto',
    'line-height': 'normal',
    'max-width': '220px',
    'min-height': '40px',
    'padding': '8px 16px',
    'text-align': 'left',
    'border': 'none',
    'border-radius': '3',
    'box-shadow': '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    'transition': 'box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1)',
    'will-change': 'box-shadow',
  },
  flex: {
    width: '100%',
    'flex-direction': 'row',
    'display': 'flex',
  },
  providericon: {
    height: '18px',
    width: '18px',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});




class SignInOrUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error :  '',
      warning : '',
      info : props.userdata.displayName + 'でログインしています',
      success : '',
    }
  }

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log("log in");
      } else {
        // 認証されていない場合
        console.log("not log in");
      }
    })
  }

  logout = () => {
    firebase.auth().signOut()
    .then(() => {
      // ログイン画面に遷移
      this.props.history.replace('/signin');
    })
    .catch(() => {
      this.setState({ error: 'ログアウトに失敗しました', warning:'',success:'',})
    });
  }

  handleTwitteronclick = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    this.getauth(provider);
  }

  handleGoogleonclick = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.getauth(provider);
  }


  getauth = (provider) => {
    auth.currentUser.linkWithRedirect(provider).then((result) => {
      // Accounts successfully linked.
      var credential = result.credential;
      var user = result.user;
      this.setState({ error: '', warning:'',success:'',})
      // ...
    }).catch((error) => {
      // Handle Errors here.
      // ...
      console.log("error: ", error)
      let errormsg = '';
      if (error.code == 'auth/provider-already-linked') {
        errormsg = '同じSNSプロバイダのアカウントは紐付けできません'
      } else {
        errormsg = error.message
      }
      this.setState({error:errormsg})
    });

    firebase.auth()
    .getRedirectResult()
    .then((result) => {
      if (result.credential) {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;
  
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = credential.accessToken;
        var secret = credential.secret;
        // ...
      }
      this.setState({success:'アカウントの紐付けに成功しました'})
      // The signed-in user info.
      var user = result.user;
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      this.setState({error:errorMessage})
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      console.log("error: ",error)
    });
  }

  openTwitterPopup = (e) => {
    e.preventDefault();
    if(windowObjectReference == null || windowObjectReference.closed)
    /* メモリ内のウィンドウオブジェクトへのポインターが存在しないか、
       そのようなポインターが存在するが、そのウィンドウが閉じられている場合 */
  
    {
      windowObjectReference = window.open('https://mobile.twitter.com/home',"linkchangeWindow", "resizable,scrollbars,status");
      /* 真であれば、ウィンドウを生成。新しいウィンドウが生成され、
         他のウィンドウの最前面に移動します。*/
    }
    else
    {
      windowObjectReference.focus();
      /* 偽であれば、ウィンドウへの参照は存在しなればならず、ウィンドウは
         閉じられていません。それゆえ、 focus() メソッドで他のウィンドウの
         最前面にそのウィンドウを移動させることができます。ウィンドウを再生成し、
         参照されているリソースを更新させる必要はありません。 */
    };
  }

  openGooglePopup = (e) => {
    e.preventDefault();
    if(windowObjectReference == null || windowObjectReference.closed)
    /* メモリ内のウィンドウオブジェクトへのポインターが存在しないか、
       そのようなポインターが存在するが、そのウィンドウが閉じられている場合 */
  
    {
      windowObjectReference = window.open('https://myaccount.google.com/',"linkchangeWindow", "resizable,scrollbars,status");
      /* 真であれば、ウィンドウを生成。新しいウィンドウが生成され、
         他のウィンドウの最前面に移動します。*/
    }
    else
    {
      windowObjectReference.focus();
      /* 偽であれば、ウィンドウへの参照は存在しなればならず、ウィンドウは
         閉じられていません。それゆえ、 focus() メソッドで他のウィンドウの
         最前面にそのウィンドウを移動させることができます。ウィンドウを再生成し、
         参照されているリソースを更新させる必要はありません。 */
    };
  }

  render() {
    const { classes } = this.props;
            
    return (
     <div className="container"> 
      <div>
        <div className={classes.root} >
            <Typography variant="headline" component="h2">
            アカウント・サービス
            </Typography>
            <Paper className={classes.root} elevation={1}>
            
              {this.state.error ? <Alert severity="error">{this.state.message}</Alert>:'' }
              {this.state.warning ? <Alert severity="warning">{this.state.warning}</Alert> : ''}
              {this.state.info ? <Alert severity="info">{this.state.info}</Alert> : ''}
              {this.state.success ? <Alert severity="success">{this.state.success}</Alert> : ''}
              <br />
             <Typography variant="headline" component="h3">
             同じプロバイダの使用アカウントの切り替え
             </Typography>
            <Typography variant="headline" component="p">
                <br />
                同じプロバイダの別のアカウントに切り替えるには目的のアカウントですでにプロバイダにログインしている必要があります<br />
                下記のリンクからログインアカウントを確認し必要があれば変更してください<br />
                スマホはリンクを長押ししてブラウザの別タブで開いてください<br />
                スマホアプリがログインしているアカウントは反映されません<br />
                <a href="https://mobile.twitter.com/home" target="linkchangeWindow" onclick={(e) =>this.openTwitterPopup(e)}>Twitter</a>
                　<a href="https://myaccount.google.com/" target="linkchangeWindow" onclick={(e) =>this.openGooglePopup(e)}>Google</a>
                <br />
                <br />
                アカウントを確認したらjoinoの現在のアカウントからログアウトしてください<br />
                自動的にログインが始まり切り替えたいアカウントに切り替えられます<br />
                <Button className={classes.providerbotton} onClick={this.logout}>
              <span>
              <FiLogOut className={classes.providericon} alt="ログオフします"/>
              </span>
                  <span > Logout from Joino:{ this.props.userdata.displayName }</span>
              </Button>
            </Typography>
                <br />
             </Paper>
              <Paper className={classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
              別のプロバイダのアカウントの紐付け
                </Typography>
                <Typography variant="headline" component="p">
              現在のアカウントに別のSNSプロバイダのアカウントを紐付けすることができます<br />
              もしSNSアカウントが利用不能になった場合も登録した別のアカウントからログインできます<br />
                同じSNSプロバイダ内の別アカウントは紐付けすることはできません<br />
                登録したいアカウントですでにプロバイダにログインしていることを下記のリンクで確認してください<br />
                スマホは長押しして別タブで開いてください<br />
              </Typography>
              <a href="https://mobile.twitter.com/home" target="linkchangeWindow" onclick={(e) =>this.openTwitterPopup(e)}>Twitter</a>
                　<a href="https://myaccount.google.com/" target="linkchangeWindow" onclick={(e) =>this.openGooglePopup(e)}>Google</a>
              <br />
              <br />
              登録したいアカウントを確認したら下記ボタンでログインしてください<br />
            <div className={classes.flex}>
            <Button className={classes.providerbotton + ' ' + classes.twittercolor} onClick={this.handleTwitteronclick}>
              <span>
              <img className={classes.providericon} alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg"/>
              </span>
                  <span > Sign in with Twitter</span>
            </Button>
              <Button className={classes.providerbotton} onClick={this.handleGoogleonclick}>
              <span>
                    <img className={classes.providericon} alt="Sign in with Google" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"/>
              </span>
                  <span > Sign in with Google</span>
              </Button>
              </div>
           </Paper>
          </div>
        </div>
     </div>
    );
  }
}
// Material-ui関連
SignInOrUp.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
  
  
  // Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(SignInOrUp);