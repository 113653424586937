import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

// スタイル
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 10,
  },
  textLeft: {
    textAlign: 'left',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
  },
});


class SpecifiedCommercialTransactionAct extends React.Component {

  render() {

    // Material-ui関連
    const { classes } = this.props;

    return (
      <div>
        <h2>特定商取引法に基づく表記</h2>
        <div className={classes.textLeft}>
        
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            事業者の名称
            </Typography>
            <Typography component="p">
            joino 鵜飼 浩司
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            事業者の所在地
            </Typography>
            <Typography component="p">
            〒500-8413 岐阜県岐阜市加納東陽町28-8
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            事業者の連絡先
            </Typography>
            <Typography component="p">
            tel:050-1520-9670 e-mail:info01@joino.me
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            サポートの対応時間
            </Typography>
            <Typography component="p">
            平日 9:00 - 17:00 (土日祝日　を除く)
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            販売価格について
            </Typography>
            <Typography component="p">
            販売価格は、表示された金額（表示価格/消費税込）と致します。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            商品以外の追加手数料
            </Typography>
            <Typography component="p">
            手数料等はありません。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            送料について
            </Typography>
            <Typography component="p">
              全商品送料無料です。<br />
              離島・遠隔地における追加の料金はありません。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            代金の支払方法と時期
            </Typography>
            <Typography component="p">
              支払方法：クレジットカードによる決済がご利用頂けます。支払時期：カード決済時にお支払いが確定致します。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            <a id="arrivalExpectedDate" name="arrivalExpectedDate">商品の引渡時期</a>
            </Typography>
            <Typography component="p">
              通常は5-7日で発送されます。お支払いからお届けまで最大、90日となります。（予約商品を除きます）
              <br />
              ９０日を経過して到着しない場合返金にて対応させていただきます。
              <br />
              ※お届け日時をお約束することはできません。ご了承ください。
              <br />
              ※商品紹介の到着予定日を大幅に越えそうな場合、お待ちいただくかキャンセルしていただくかお選びいただくことがあります。
            </Typography>
          </Paper>
          <Paper className={classes.root} elevation={1}>
            <Typography variant="headline" component="h3">
            <a id="Refundsupport" name="Refundsupport">返品・返金についての特約に関する事項</a>
            </Typography>
            <Typography component="p">
              商品に欠陥がある場合を除き、基本的には返品・返金には応じません。
              <br />
              到着後１週間を経過後は、いかなる理由でも返品・返金には応じません。
              <br />
              返品にかかる送料は返送先が海外であってもお客様の負担となります。
              <br />
              欠品やモデルチェンジ、価格の変更等が発生し予定価格、予定到着日でお送りすることが困難な場合、返金にて対応させていただきます。
              <br />
              お客様の被ったいかなる損害につきましても商品価格の返金以上の対応は一切できかねます。
            </Typography>
          </Paper>
          
        </div>
      </div>
    );
  }
}

// Material-ui関連
SpecifiedCommercialTransactionAct.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};


// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(SpecifiedCommercialTransactionAct);