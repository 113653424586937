import React from 'react';
import PropTypes from 'prop-types';

import firebase from '../Firebase';
import "firebase/auth";
import { db } from '../Firebase';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';

// Material-UIアイコン取得
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import HomeIcon from '@material-ui/icons/Home';
import ShareIcon from '@material-ui/icons/Share';
import SubjectIcon from '@material-ui/icons/Subject';    //特定商取引
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'; //プライバシーポリシー
import FavoriteIcon from '@material-ui/icons/Favorite'; //JoinoShopi
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'; //JoinoPromise
import Person from '@material-ui/icons/Person';//アカウントサービス
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';//SignUp


// Route関連
//import { Link } from 'react-router-dom';
import { Link,} from 'react-router-dom';

// コンテナの準備
import ShareDialog from '../containers/ShareDialog';

// コンポーネントの準備
import ResponsiveDrawerListItem from '../components/ResponsiveDrawerListItem';

// 設定値
const drawerWidth = 240;
const headerNavigationHeight = 56;
const bottomNavigationHeight = 0;

// スタイル
const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  toolBar: {
    justifyContent: 'space-between', // 中央寄せのため追加
    minHeight: bottomNavigationHeight,
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    paddingTop: `calc(10px + ${headerNavigationHeight}px)`,
    paddingBottom: `calc(10px + ${bottomNavigationHeight}px)`,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 10,
    },
  },
  
  // ヘッダーロゴ
  headerLogo: {
    display: 'flex',
    height: 48,
  },

  //アイコン
  trimIcon: {
    position:'relative',
    overflow: 'hidden',
    width:'48px',
    height:'48px',
    'border-radius':'50%',
  },
  trimIconimg:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '-webkit-transform': 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    height:'100%',
  }

});

window.isLoad = () => { return true; }

class ResponsiveDrawer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      shareDialogOpen: false,
      item: { ambassador: { ambassadordisplayName: '', photoURL: '' } },
      ambassador: { ambassadordisplayName: '' },
      tags: [],
      rt: 0,//再描画を確実にするためのカウンター
      tagname:"",
    };
  }

  newChildren = React.cloneElement(this.props.children, { shareDialogToggle: this.shareDialogToggle });

  closeDrawerNav = () => {
    this.setState({
      mobileOpen: false
    });
  }
/*
  closeDrawerNav2 = () => {
    this.newChildren = React.cloneElement(this.props.children, { shareDialogToggle: this.shareDialogToggle });
    this.setState({
      mobileOpen: false
    });
  }
*/
  
  closeDrawerNav2 = () => {
    this.setState({
      mobileOpen: false
    });
    setTimeout("window.location.reload(true)", 200);
  }


  openDrawerNav = () => {
    this.setState({
      mobileOpen: true
    });
  }
  
  // シェアボタン挙動
  shareDialogToggle = () => {
    this.setState({ shareDialogOpen: !this.state.shareDialogOpen });
  }

  getItem = async (ambassadorid, itemId) => {
    const docRef = db.collection("users").doc(ambassadorid).collection("items").doc(itemId);
    //const docRef = db.collection("item").doc(itemId);
    const doc = await docRef.get();
    var skuAmountvalue = [0, 0];
    
        //ドキュメントの存在確認
    if (doc.exists) {
      const dat = doc.data();
      console.log("doc.exists itemId:" + dat.itemId);
      var mainimages = dat.imageModule.imagePathList;
      /*
      dat.skuModule.productSKUPropertyList.map(_lst => {
        _lst.skuPropertyValues.map(_lst2 => {
          _lst2.selected = false;
        });
      });
      */
      if ( 'minActivityAmount' in dat.priceModule) {
        skuAmountvalue[0] = dat.priceModule.minActivityAmount.value;
      }
      else if ( 'minAmount' in dat.priceModule) {
        skuAmountvalue[0] = dat.priceModule.minAmount.value;
      }  
      if ( 'maxActivityAmount' in dat.priceModule) {
        skuAmountvalue[1] = dat.priceModule.maxActivityAmount.value;
      }
      else if ( 'maxAmount' in dat.priceModule) {
        skuAmountvalue[1] = dat.priceModule.maxAmount.value;
      }
      if (dat.ambassador) {
        this.gettaglist(dat.ambassador.uid,"");
      }
      else {
        dat.ambassador = { ambassadordisplayName: '' };        
      }
      this.setState({
        item: dat,
        mainimages: mainimages,
        skuAmountvalue: skuAmountvalue,
        shareUrl:"https://joino.me/i/" + ambassadorid + "/" + itemId,
        title: dat.pageModule.title,
        tags:dat.tags,
        DialogTitle:"この商品をシェアする",
      });
    } else {
      console.log("ResponsiveDrawer no_data")
    }
  }

  gettaglist = (uid,tagname) => {
    let taglst = [];
    const docRef2 = db.collection("users").doc(uid);
    docRef2.get().then((doc) => {
      //ドキュメントの存在確認
      if (doc.exists) {
        const dat = doc.data();
        taglst = dat.tags;
        let items = [];
        for (let i = 0; i < taglst.length; i++) {
          items.push(
            <ResponsiveDrawerListItem
              to={"/l/" + uid + "/" + taglst[i]}
              onClick={this.closeDrawerNav2}
              icon={""}
              text={taglst[i]}
            />
          );
        }
        // return <>{items}</>
        this.setState({
          taglst: items,
          tagname:tagname,
        });
      }
    });
  }

  componentDidMount = () => {
    //Recommended by
    const url = window.location.href;
    const urlarry = url.split('/');
    if (urlarry[3].toLowerCase() == "i") {
      this.getItem(urlarry[4], urlarry[5]);
    }
    if (urlarry[3].toLowerCase() == "l") {
      this.gettaglist(urlarry[4],urlarry[5]);
    }
    else  {
      //ログインしてるかどうかチェック
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          if (urlarry[3].toLowerCase() == "itemedit") {
            const docRef = db.collection("users").doc(user.uid).collection("items").where("sourceItemId", "==", urlarry[4].split('?')[0]);
            docRef.get()
              .then((doc) => {
                //ドキュメントの存在確認
                if (doc.docs.length) {
                  this.getItem(user.uid, doc.docs[0].id);
                }
              })              
          }
          const docRef2 = db.collection("users").doc(user.uid);
          docRef2.get().then((doc) => {
            //ドキュメントの存在確認
            if (doc.exists) {
              this.setState({
                ambassador: doc.data(),
              });
            }
          });
        }
      });
    }
  }

  seticon = () =>{
    const { classes, theme } = this.props;
    return (
      <>
        <Link to="/">
          <Typography variant="title" color="inherit" noWrap>
            {this.state.item.ambassador.photoURL
              ? <div className={classes.trimIcon}><img src={this.state.item.ambassador.photoURL} alt="" className={classes.trimIconimg} /></div>
              : this.state.ambassador
                ? <div className={classes.trimIcon}><img src={this.state.ambassador.photoURL} alt="" className={classes.trimIconimg} /></div>
                :<img src="/images/joino10040.png" alt="" className={classes.headerLogo} />}
          </Typography>
        </Link>
        {this.state.item.ambassador.ambassadordisplayName ?<> <Typography variant="h4" color="inherit" noWrap> {this.state.item.ambassador.ambassadordisplayName}</Typography></> :
          this.state.ambassador.ambassadordisplayName ? <> logged in <Typography variant="h5" color="inherit" noWrap>{this.state.ambassador.ambassadordisplayName}</Typography></> :
            this.state.tagname ? <><Typography variant="h5" color="inherit" noWrap>{decodeURI(this.state.tagname)}</Typography></>:<></>}
      </>
    );
  }

  render() {
    // Material-ui関連
    const { classes, theme } = this.props;
    const drawer = (
      <div>
          <List>
            {this.state.taglst}
          </List>          
          <Divider />
        <List>
        <ResponsiveDrawerListItem
            to="/"
            onClick={this.closeDrawerNav}
            icon={<HomeIcon />}
            text="joinoとは"
          />
        </List>
        {this.state.ambassador.ambassadordisplayName ? <></> : <>
        </>}
          <Divider />
          <List>
          <ResponsiveDrawerListItem
            to="/joinopromi"
            onClick={this.closeDrawerNav}
            icon={<AssignmentTurnedInIcon />}
            text="joinopromi"
          />
          <ResponsiveDrawerListItem
            to="/joinoshopi"
            onClick={this.closeDrawerNav}
            icon={<FavoriteIcon />}
            text="joinoshopi"
          />
      {this.state.ambassador.ambassadordisplayName
       ? <>
        <ResponsiveDrawerListItem
            to="/signin"
            onClick={this.closeDrawerNav}
            icon={<SupervisorAccount />}
            text="アカウントサービス"
          />
        <ResponsiveDrawerListItem
            to="/itemedit/new"
            onClick={this.closeDrawerNav}
            icon={<FavoriteIcon />}
            text="新規アイテム"
            />
        <ResponsiveDrawerListItem
            to="/itemlist"
            onClick={this.closeDrawerNav}
            icon={<FavoriteIcon />}
            text="アイテムリスト"
            />
          </>
        : <>
        <ResponsiveDrawerListItem
            to="/signup"
            onClick={this.closeDrawerNav}
            icon={<ExitToAppIcon />}
            text="新規登録・ログイン"
            />
          </>}
          <ResponsiveDrawerListItem
            to="/specifiedcommercialtransactionact"
            onClick={this.closeDrawerNav}
            icon={<SubjectIcon />}
            text="特定商取引法に基づく表記"
          />
          <ResponsiveDrawerListItem
            to="/privacypolicy"
            onClick={this.closeDrawerNav}
            icon={<AssignmentIndIcon />}
            text="プライバシーポリシー"
          />
          <ResponsiveDrawerListItem
            to="/info"
            onClick={this.closeDrawerNav}
            icon={<InfoIcon />}
            text="joino運営"
          />
          <ResponsiveDrawerListItem
            to="/settings"
            onClick={this.closeDrawerNav}
            icon={<SettingsIcon />}
            text="設定"
          />
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="fixed">
          <Toolbar className={classes.toolBar} variant="dense">
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => this.openDrawerNav()}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
            { this.seticon()}
            <IconButton
              color="inherit"
              aria-label="Open Share"
            >
              <Typography variant="button" color="inherit" noWrap>
                <ShareIcon onClick={this.shareDialogToggle}/>
                <ShareDialog
                  open={this.state.shareDialogOpen}
                  onClose={this.shareDialogToggle}
                  shareUrl={this.state.shareUrl ? this.state.shareUrl : "https://joino.me/"}
                  title={this.state.title ? this.state.title : "joino!me"}
                  DialogTitle={this.state.DialogTitle ? this.state.DialogTitle : "Joino.meをシェアする"}
                  tags={ this.state.tags}
                />
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={this.state.mobileOpen}
            onClose={this.closeDrawerNav}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          {/*this.props.children*/ this.newChildren}
        </main>
      </div>
    );
  }
}

// Material-ui関連
ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定＋Redux設定
export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);