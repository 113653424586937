import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase, { db } from '../Firebase';


// コンポーネントの準備
import Item from '../components/Item'
import { FaBullseye, FaLessThanEqual } from 'react-icons/fa';

// スタイル
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    'textAlign': 'center',
    'align': 'center',
    width:'90%',
  },
  textcenter: {
    width:'100%',
    align:'center',
    'white-space': 'nowrap',  
    overflow: 'hidden',  
    'text-overflow': 'ellipsis', 
    '-webkit-text-overflow': 'ellipsis',  
    '-o-text-overflow': 'ellipsis',
    'textAlign': 'center',
    'text-align':'center',
  },
  accordionSummary: {
    height: '15px',
    'min-height': '15px',
    padding: '0',
    margin: '0',
  },
  accordionDetails: {
    padding: '0',
    margin: '0',
    display: 'flex',
    'flex-wrap':'wrap',
//    'flex-flow': 'column',
  },
  'expanded': {
   'min-height': '15px',
    padding: '0',
    margin: '0',
  },
  resize:{
    height: '15px',
    fontSize: '12px',
    padding: '0',
    margin: '0',
    width:'20px',
  },
  searchbox: {
    width: '100%',
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
  },
  categlybox: {
    width: '100%',
    display: 'flex',
    'flex-wrap': 'wrap',
    'flex-direction': 'row',
    'justify-content': 'space-evenly',
  },
  inlinetext: {
    top:"-10px",
    fontSize: '12px',
  },
  searchbottn: {
    top:"-5px",
    'line-height': '16px',    
  },
  serchtext: {
    width:'50px'
  },
  pricetext: {
    width:'50px'
  },
  selectSortType: {
    fontSize: '10px',
    'line-height': '0px',
  },
});

class L extends React.Component {

  datalimit = 50;

  constructor(props) {
    super(props)
    this.state = {
      favCategory: [],
      currentCategory: {categoryId:"0",categoryEnName:"root",categoryName:"Top"},
      categoryLst: [],
      itemList: [],
      getting: true,
      page: 0,
      error: '',
      endofdata:false,
    }
  }
  
  //python.get()
  get = (dic, key) => {
    if (dic && key in dic) return dic[key]
    return null
  }

  getData = async () => {
    if (this.state.endofdata) {
      return
    }
    let bufitemList = [];
    let docref;
    let userdat;
    let endofdata = false;
    const tagname = this.props.match.params.tagName
    const userref = db.collection('users').doc(this.props.match.params.userId)
    const userdoc = await userref.get();
    if (userdoc.exists) {
      userdat = userdoc.data();
    }
    if (this.state.lastVisible) {
      console.log('lastVisible: ture')
      docref = db.collection('users')
        .doc(userdoc.id).collection('items')
        .where("status", "==", "available") //delete edit available
        .where("tags", "array-contains", tagname)
        .orderBy("dt", "desc")
        .startAfter(this.state.lastVisible)
        .limit(this.datalimit)
    }
    else if(this.state.page == 0) {
      console.log('lastVisible: false')
      docref = db.collection('users')
        .doc(userdoc.id).collection('items')
        .where("status", "==", "available")
        .where("tags", "array-contains", tagname)
        .orderBy("dt", "desc")
        .limit(this.datalimit)
    }
    else {
      return
    }

    const querySnapshot = await docref.get()

    if (querySnapshot.size < this.datalimit) {
        endofdata = true
    }

    querySnapshot.forEach((doc) => {
      const docdat = doc.data();
      let price
      if ('minActivityAmount' in docdat.priceModule) {
        price = docdat.priceModule.minActivityAmount.value;
      }
      else if ('maxActivityAmount' in docdat.priceModule) {
        price = docdat.priceModule.maxActivityAmount.value;
      }
      else if ('minAmount' in docdat.priceModule) {
        price = docdat.priceModule.minAmount.value;
      }
      else if ('maxAmount' in docdat.priceModule) {
        price = docdat.priceModule.maxAmount.value;
      }

      
      const dat = {
        title: { displayTitle: docdat.pageModule.title.split("|")[0] },
        image: { imgUrl: docdat.imageModule.imagePathList[0]?docdat.imageModule.imagePathList[0].replace('https:', ''):"" },
        prices: { salePrice: { formattedPrice: "￥" + parseInt(price).toLocaleString(),value:parseInt(price)} },
        shipping:(docdat.freightAmount.shipping_price ? parseInt(docdat.freightAmount.shipping_price.replace(/[^0-9]/g, '')) ? parseInt(docdat.freightAmount.shipping_price.replace(/[^0-9]/g, '')) : 0 : 0),
        productId: docdat.sourceItemId,
        dt: docdat.dt,
        itemId: docdat.itemId,
      }
      bufitemList.push(dat);
    });

    bufitemList = this.state.itemList.concat(bufitemList);
    this.setState({
      endofdata:endofdata,
      error: '',
      page: this.state.page + 1,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length-1],
      itemList: bufitemList,
      userdata:userdat,
      getting:false,
    });             
  }

  watchCurrentPosition() {
    const pos = this.topParts.clientHeight + this.topParts.getBoundingClientRect().top; //clientHeight: 42710  document.getElementById("scrolldiv").getBoundingClientRect().top
    window.itemlistrepos = pos;
    if (pos < 10000 && this.state.getting==false) {
      console.log('pos: ', pos)
      this.setState({
        getting: true,
      }, () => {
        this.getData();
      });
    }
  }

  //値を取得
  componentDidMount = () => {
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true)
    this.getData();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', event => this.watchCurrentPosition(), true)
  }

  render() {
    // Material-ui関連
    const { classes } = this.props;
    const Items = this.state.itemList? this.state.itemList:[];
    const ItemsLength = Items ? Items.length : 0;
    let itemCards = [];
    //商品
    for (let index = 0; index < ItemsLength; index++) {
      const title = Items[index].title?Items[index].title.displayTitle:'';
      const imgUrl = Items[index].image?"https:" + Items[index].image.imgUrl.split('_220x220')[0]:"";
      const price = Items[index].prices.salePrice ? Items[index].prices.salePrice.value: 0;
      const price2 = parseInt(price * this.state.userdata.MarkupRatio, 10);
      const shipping = Items[index].shipping;
      const shippingprice = shipping.toString().replace(/[^0-9]/g, '');
      const shippingprice2 = shippingprice ? parseInt(shippingprice):0;
      //送料無料対策
      const trueprice = '￥ ' + (price2 + shippingprice2).toLocaleString();
      const uid = this.state.userdata.uid;
      const itemid = Items[index].itemId;
      const href = "/i/" + uid + "/" + itemid;
      const deletable = false;
      const status = ""
      if ((price2 + shippingprice2) > 150) {
        itemCards.push(<Item empty={false} title={title} imgUrl={imgUrl} price={trueprice} href={href} uid={uid} itemid={itemid} getMylist={this.getData} deletable={deletable}/>);
      }
    }
    // 読み込み中はロード画面にする
    const errors = () => {
      switch (this.state.error) {
        case 'data-error':
          return (
            <p>データがありません</p>
          )
        case 'not login':
          return (
            <p>ログインが必要です</p>
          )
        case 'no_data':
          return (
            <p>
              <CircularProgress /> <p>データを取得しています</p>
            </p>
          )
        default:
          return (
            this.state.error?<p>{this.state.error}</p>:""
          )
      }
    }
    return (
      <>
        {this.get(this.state, "error")?<div class="overflow">{errors()}</div>:""}

        <div className={classes.textcenter} ref={(div) => {this.topParts = div }} id="scrolldiv">
          {itemCards}         {/* 無限スクロールで表示する項目 */}
          {this.state.getting && this.state.endofdata == false ? <CircularProgress /> :
             this.state.endofdata == true ? <> - END - </> :<></>}
        </div>
      </>
    );
  }
}

// Material-ui関連
L.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

// Material-uiのテーマ設定
export default withStyles(styles, { withTheme: true })(L);