import React from "react";
import "firebase/functions";
import firebase from "firebase/app";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";
//import { sold } from "../../reducks/opuses/operations";
//Function URL (createPaymentSession(us-central1)): https://us-central1-joino-fc334.cloudfunctions.net/createPaymentSession


const useStyles = makeStyles({
  button: {
    backgroundColor: "#69b076",
    color: "#FFF",
    fontSize: 20,
    height: 26,
    width: "auto",
    padding: "0px 30px",
  },
});

const PurchaseButton = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const price = props.price;
  const name = props.name;
  const image = props.image;
  const description = props.description;
  const ambassadorid = props.ambassadorid;
  const ambassadordisplayName = props.ambassadordisplayName;
  const productid = props.productid;
  const arrivalExpectedDate = props.arrivalExpectedDate;
  const freightAmountString = props.freightAmountString;


  const showPayment = async (price, name, image, description, ambassadorid, productid, arrivalExpectedDate,freightAmountString) => {
    // セッション作成functionをインスタンス化
    const createPaymentSession = firebase.app().functions('asia-northeast1').httpsCallable("createPaymentSession");
    // firebase.functions();
    

    // 公開可能キーをもとに、stripeオブジェクトを作成
    /*
    <YOUR STRIPE PUBLISHABLE KEY> 
    テスト用 pk_test_51IfbjdGCCxXUAFn5XO5MVPk9108OzGHopN2rnZ2TvYpgkjWlOqT7CcCh2WZujcrRFE3xC4PnS1qsGi05jeukSWkS00xUhFXoIj
    本番用 pk_live_51IfbjdGCCxXUAFn5z7BHA61QSzHsQz8SHveENAJRuXWDYbEFVlhWfraxjAcVKUycA2FqiqdjKF20Sg7dto4LFYYU00WpMqT4zj

    さらにコマンドラインでシークレットの変更も必要
    Stripe Secret の構成を更新します。
    firebase functions:config:set stripe.secret=<YOUR STRIPE LIVE SECRET KEY>
    public/javascript/app.js で公開可能なライブキーを設定します。
    変更を有効にするために、Cloud Functions と Hosting の両方を再デプロイします。firebase deploy。
    https://firebase.google.com/docs/use-cases/payments?hl=ja
    */

    const stripePromise = loadStripe(
      //"pk_test_51IfbjdGCCxXUAFn5XO5MVPk9108OzGHopN2rnZ2TvYpgkjWlOqT7CcCh2WZujcrRFE3xC4PnS1qsGi05jeukSWkS00xUhFXoIj"
      "pk_live_51IfbjdGCCxXUAFn5z7BHA61QSzHsQz8SHveENAJRuXWDYbEFVlhWfraxjAcVKUycA2FqiqdjKF20Sg7dto4LFYYU00WpMqT4zj"
    );
    const stripe = await stripePromise;

    // セッション情報をもとに、支払いページに遷移
    createPaymentSession({
    // ここでFunctionsにオブジェクト型で色々渡せますので、showPaymentの引数から持ってきます
    // 上でpropsから取り出しているのでそこも注意
      price: price,
      name: name,
      image: image,
      description: description,
      ambassadorid: ambassadorid,
      ambassadordisplayName:ambassadordisplayName,
      productid: productid,
      arrivalExpectedDate: arrivalExpectedDate,
      freightAmountString:freightAmountString,
    }).then((result) => {
      console.log("sessionId:" + result.data.id);
      stripe
        .redirectToCheckout({
          sessionId: result.data.id,
        })
        .then((result) => {
          console.log(result);
        });
        //決済が成功したら実行される関数です。詳しくは割愛しますが、ここでは商品が売却済みになるようにデータを渡しています
        //dispatch(sold(ambassadorid,productid));
    });
  };

  return (
    <Button className={classes.button} variant="contained" onClick={() => showPayment(price, name, image, description, ambassadorid, productid, arrivalExpectedDate,freightAmountString)}>
      {props.label}
    </Button>
  );
};
export default PurchaseButton;
